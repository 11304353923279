import React from "react";

function PersonComp({ title, length, dataMap, handleShowModal }) {
  return (
    <div className="w-full flex justify-between items-center  ">
      <div className="w-[200px]">
        <h1 className="font-semibold">{title}</h1>
        <p>{length} Employee</p>
      </div>

      <div className="flex flex-wrap gap-2 w-full">
        {dataMap.map((item, index) => {
          return (
            <div key={index}>
              <div className="border-[4px] border-white shadow-xl rounded-full flex justify-center items-center">
                <div
                  onClick={() => handleShowModal(item)}
                  style={{ backgroundImage: `url(${item.photo})` }}
                  className="w-[56px] h-[56px] rounded-full cursor-pointer bg-cover bg-center"
                ></div>
              </div>
            </div>
          );
        })}

        {/* <div className="border-[4px] border-white shadow-xl rounded-full flex justify-center items-center">
        <div className="w-[56px] h-[56px] rounded-full bg-orange-500 flex justify-center items-center text-white">
          <h1 className="text-sm">+126</h1>
        </div>
      </div> */}
      </div>
    </div>
  );
}

export default PersonComp;
