import { useFormikContext } from "formik";
import { useEffect } from "react";
import { alertError } from "utils/alert";

const FormErrorNotificationShared = ({ disableAlert = false }) => {
  const { isValid, isValidating, isSubmitting, errors, setSubmitting } =
    useFormikContext();

  useEffect(() => {
    if (!isValid && !isValidating && isSubmitting) {
      const flatErrors = {};
      const stack = [{ obj: errors, prefix: "" }];

      // Flatten errors
      while (stack.length > 0) {
        const { obj, prefix } = stack.pop();
        for (const key in obj) {
          const fullKey = prefix ? `${prefix}.${key}` : key;
          if (
            obj[key] &&
            typeof obj[key] === "object" &&
            !Array.isArray(obj[key])
          ) {
            stack.push({ obj: obj[key], prefix: fullKey });
          } else {
            flatErrors[fullKey] = obj[key];
          }
        }
      }

      const errorEntries = Object.entries(flatErrors);
      if (!errorEntries.length) return;

      // Get all error elements that exist in DOM
      const errorElements = errorEntries
        .map(([fieldName]) => ({
          fieldName,
          element: document.getElementById(fieldName),
        }))
        .filter((item) => item.element);

      if (!errorElements.length) return;

      // Find first error element in DOM
      const firstErrorElement = errorElements.reduce((first, current) => {
        if (!first.element) return current;
        return first.element.compareDocumentPosition(current.element) &
          Node.DOCUMENT_POSITION_FOLLOWING
          ? first
          : current;
      });

      // Scroll to first error
      firstErrorElement.element?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });

      // Get error message for the field
      const errorMessage = flatErrors[firstErrorElement.fieldName];

      if (firstErrorElement.element && !disableAlert) {
        alertError(`${errorMessage}!`);
      }

      setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, isValid, isValidating, errors]);
  return null;
};

export default FormErrorNotificationShared;
