import * as yup from "yup";

export const companyDetailSchema = yup.object().shape({
  uenNumber: yup.string().min(2).required("Uen Number is Required"),
  companyID: yup.string().min(2).required("company ID is required"),
  companyName: yup
    .string()
    .matches(/^[^&]+$/, 'The "&" symbol is not allowed')
    .min(2)
    .required("Company Name is Required"),
  companyImage: yup
    .mixed()
    .required("You need to provide a file")
    .test("fileSize", "The file is too large", (value) => {
      if (value && value.size >= 2000000) {
        return false;
      }
      return true;
    })
    .test(
      "type",
      "Only the following formats are accepted: .jpeg, .jpg .png",
      (value) => {
        if (
          value &&
          (!value.type === "image/jpeg" ||
            !value.type === "image/png" ||
            !value.type === "image/jpg")
        ) {
          return false;
        }
        return true;
      }
    ),
  employeeHeadCount: yup
    .string()
    .min(2)
    .required("Employee Head Account is Required"),
  industryField: yup
    .string()
    .min(2)
    .required("Industry Best Field is Required"),
  country: yup.string().min(2).required("Country is Required"),
  companyCountryPhoneNumber: yup
    .number()
    .min(6)
    .required("Company Country Phone Number is Required"),
  companyPhoneNumber: yup
    .number()
    .min(6)
    .required("Company Phone Number is Required"),
  postalCode: yup.number().min(4).required("Postal Code is Required"),
  address: yup.string().min(5).required("Address is Required"),
});
