import React, { useState, useEffect } from "react";
import { Accordion, Button, DataTable } from "@bluesilodev/timhutcomponents";
import { useFormikContext } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ChevronSvg, FiPlusSvg } from "components/icons";
import { useGetLeaveById, useGetLeaveGlobal } from "service/leaveApi";

import EmployeeMentDetailShare from "pages/shared/formUser/EmployeementDetailShare";
import ActionLeave from "pages/shared/actionLeave";
import { setEditLeaveData } from "store/employeeSlice";
import { LeaveTypeAddModalForm } from "pages/shared/formLeaveType";

function EmployeementDetails({ canEditLeaveSetting }) {
  const { values, handleBlur, handleChange, errors, touched, setFieldValue } =
    useFormikContext();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { leaveEditData } = useSelector((state) => state.employeeSlice);
  const [addLeaveTypeModal, addLeaveTypeOpen] = useState(false);
  // COLUMN
  const dataColumn = [
    {
      id: "leaveType",
      accessorFn: (row) => row.name,
      header: () => <span>Leave Type</span>,
      enableSorting: true,
    },
    {
      id: "amount",
      accessorFn: (row) => row.defaultAmount,
      header: () => <span>Default Amount</span>,
      enableSorting: true,
    },
    {
      id: "gainPermont",
      accessorFn: (row) => row.gainPerMonth,
      header: () => <span>Gain Per Month</span>,
      enableSorting: true,
    },
    {
      id: "expired",
      accessorFn: (row) => row.expiredCarryOver,
      header: () => <span>Expired Caryy Over</span>,
      enableSorting: true,
    },
    {
      id: "maxCarry",
      accessorFn: (row) => row?.maxCarryOver,
      header: () => <span>Max Carry Over</span>,
      enableSorting: true,
    },

    {
      id: "action",
      disableSorting: true,
      header: () => "",
      accessorFn: (row) => {
        return row && row;
      },
      cell: (param) => {
        if (param) {
          const data = param.getValue();

          return (
            <>
              <ActionLeave
                data={data}
                // canEditLeaveSetting={canEditLeaveSetting}
              />
            </>
          );
        }
      },
    },
  ];

  const { uId } = useParams();

  const { data: dataIndividual, isSuccess: succesIndividual } = useGetLeaveById(
    uId,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getLeaveDataID");
      },
      onError: (err) => {
        console.log("ERR : ", err);
      },
    }
  );

  const { data: globalData, isSuccess: successGlobal } = useGetLeaveGlobal({
    onSuccess: () => {
      queryClient.invalidateQueries("globalLeave");
    },
    onError: (err) => {
      console.log("ERR: ", err);
    },
  });

  useEffect(() => {
    if (succesIndividual && successGlobal && dataIndividual && globalData) {
      if (
        dataIndividual.data.leaveTypeSettings &&
        dataIndividual.data.leaveTypeSettings.length > 0
      ) {
        dispatch(setEditLeaveData(dataIndividual.data.leaveTypeSettings));
      } else {
        dispatch(setEditLeaveData(globalData.data));
      }
    }
  }, [succesIndividual, successGlobal, dataIndividual, globalData]);

  return (
    <div className="w-full py-5">
      <EmployeeMentDetailShare
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        touched={touched}
        values={values}
        setFieldValue={setFieldValue}
      />

      <div className="py-5">
        <Accordion
          title={"Leave Settings"}
          icons={[
            {
              // Conditionally render the button based on canEditLeaveSetting
              icon: canEditLeaveSetting ? (
                <div className="p-2 ">
                  <Button
                    type={"button"}
                    className={"w-[200px] "}
                    style={"solid"}
                    label={
                      <div className="w-full text-white flex items-center justify-center p-2 gap-2">
                        <FiPlusSvg
                          className={"text-white w-[20px] h-[20px] "}
                        />
                        <h1>Add New Leave Type</h1>
                      </div>
                    }
                    onClick={() => {
                      addLeaveTypeOpen(true);
                    }}
                  />
                </div>
              ) : null,
            },
          ]}
          children={
            <div className="py-5">
              <div className="pt-5">
                <DataTable
                  columns={dataColumn}
                  data={leaveEditData ? leaveEditData : []}
                  maxHeight={450}
                />
              </div>
            </div>
          }
        />
        <LeaveTypeAddModalForm
          addLeaveTypeModal={addLeaveTypeModal}
          addLeaveTypeOpen={addLeaveTypeOpen}
          // canEditLeaveSetting={canEditLeaveSetting}
        />
      </div>
    </div>
  );
}

export default EmployeementDetails;
