import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { ModalConfirmationV2 } from "@bluesilodev/timhutcomponents";

import { useDeleteUser } from "service/userAPI";
import { alertSuccess, alertError } from "utils/alert";
import {
  adminAndSuperVisorRole,
  superAdminRole,
} from "utils/roleFromLocalStorage";

import FillTableMenu from "components/FillTableMenu";
import { useSelector } from "react-redux";

function TableMenu({ data }) {
  const [show, setShow] = useState(false);
  const [uIdData, setuIdData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { currentRole } = useSelector((state) => state.loginSlice);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleShow = () => {
    setShow(!show);
  };

  const onEditEmployee = (uId) => {
    navigate(`/super/editEmployee/${uId}`);
  };

  // DELETE USER
  const { mutate, isPending } = useDeleteUser(uIdData, {
    onSuccess: () => {
      queryClient.invalidateQueries("getUser");
      alertSuccess("SUCCESS DELETED DATA");
    },
    onError: () => {
      alertError("FAILED DELETE DATA!");
    },
  });

  const onDeleteEmployee = (uId) => {
    setuIdData(uId);
    setShowModal(true);
  };

  const confirmModal = () => {
    mutate(uIdData);
    setShowModal(false);
    setShow(false);
  };

  const cancelModal = () => {
    setShowModal(false);
    setShow(false);
  };

  if (isPending) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="relative">
        <FillTableMenu
          admin={adminAndSuperVisorRole || superAdminRole}
          handleShow={handleShow}
          onDeleteEmployee={() => onDeleteEmployee(data?.uId)}
          onEditEmployee={() => onEditEmployee(data?.uId)}
          showSetting={show}
          setShow={setShow}
          currentRole={currentRole}
        />

        {showModal && (
          <ModalConfirmationV2
            className={"min-w-[600px]"}
            title={"Delete Employee"}
            headMessage={""}
            message={
              <div className="text-left w-full text-lg font-light">
                Do you want to delete this item?
              </div>
            }
            onClose={cancelModal}
            onConfirm={confirmModal}
          />
        )}
      </div>
    </>
  );
}

export default TableMenu;
