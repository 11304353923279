import { createSlice } from "@reduxjs/toolkit";

// SLICE REDUCER
const employeeSlice = createSlice({
  name: "employeeSlice",
  initialState: {
    study: [],
    dataEditStudy: [],
    leaveData: [],
    leaveEditData: [],
    accountStudy: [],
    currentFormStep: 1,
    employeeType: "",
    statePage: {
      totalData: 0,
      rowsPerPage: 10,
      currentPage: 1,
    },
  },
  reducers: {
    setEmployeeStudy(state, action) {
      state.study = action.payload;
    },
    setEmployeeType(state, action) {
      state.employeeType = action.payload;
    },

    setDataEditStudy(state, action) {
      state.dataEditStudy = action.payload;
    },

    setAccountStudy(state, action) {
      state.accountStudy = action.payload;
    },

    setPagination(state, action) {
      state.statePage = action.payload;
    },

    // SET TO ADD EMPLOYEE
    setLeaveData(state, action) {
      state.leaveData = action.payload;
    },

    updateLeaveData(state, action) {
      const index = state.leaveData.findIndex(
        (item) => item.uId === action.payload.uId
      );

      if (index !== -1) {
        state.leaveData[index] = action.payload;
      }
    },

    // SET TO EDIT EMPLOYEE
    setEditLeaveData(state, action) {
      state.leaveEditData = action.payload;
    },

    updateEditLeaveData(state, action) {
      const index = state.leaveEditData.findIndex(
        (item) => item.uId === action.payload.uId
      );

      if (index !== -1) {
        state.leaveEditData[index] = action.payload;
      }
    },

    // SET CURRENT EDIT STEP
    setCurrentFormStep(state, action) {
      state.currentFormStep = action.payload;
    },
  },
});

export const {
  setEmployeeStudy,
  setAccountStudy,
  setDataEditStudy,
  setLeaveData,
  updateLeaveData,
  updateEditLeaveData,
  setEditLeaveData,
  setPagination,
  setCurrentFormStep,
  setEmployeeType,
} = employeeSlice.actions;
export default employeeSlice.reducer;
