import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { ModalConfirmation } from "@bluesilodev/timhutcomponents";

import { useDeleteLocation } from "service/locationAPI";
import { alertSuccess, alertError } from "utils/alert";
import { adminRole } from "utils/roleFromLocalStorage";

import FillTableMenu from "components/FillTableMenu";
import { useSelector } from "react-redux";

function TableMenu({ data }) {
  const [show, setShow] = useState(false);
  const [uIdData, setuIdData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { currentRole } = useSelector((state) => state.loginSlice);

  const handleShow = () => {
    setShow(!show);
  };

  const onEditEmployee = (uId) => {
    navigate(`/editLocation/${uId}`);
  };

  // DELETE USER
  const { mutate, isPending } = useDeleteLocation(uIdData, {
    onSuccess: () => {
      queryClient.invalidateQueries("getLocationById");
      alertSuccess("SUCCESS DELETED DATA");
    },
    onError: () => {
      alertError("FAILED DELETE DATA!");
    },
  });

  const onDeleteEmployee = (uId) => {
    setuIdData(uId);
    setShowModal(true);
  };

  const confirmModal = () => {
    mutate(uIdData);
    setShowModal(false);
    setShow(false);
  };

  const cancelModal = () => {
    setShowModal(false);
    setShow(false);
  };

  if (isPending) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="relative">
        <FillTableMenu
          currentRole={currentRole}
          handleShow={handleShow}
          onDeleteEmployee={() => onDeleteEmployee(data?.uId)}
          onEditEmployee={() => onEditEmployee(data?.uId)}
          showSetting={show}
          setShow={setShow}
        />

        {showModal && (
          <ModalConfirmation
            title="Delete Location"
            headMessage="Are you sure want to delete it?"
            onClose={cancelModal}
            onConfirm={confirmModal}
          />
        )}
      </div>
    </>
  );
}

export default TableMenu;
