import React from "react";
import PropTypes from "prop-types";

function FiPlusSvg({ className, ...props }) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...props}
    >
      <path
        d="M12 5V19"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 12H19"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

FiPlusSvg.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

FiPlusSvg.defaultProps = {
  className: "w-[20px] h-[20px]",
};

export default FiPlusSvg;
