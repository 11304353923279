import React, { useState, useRef, useLayoutEffect } from "react";
import { SearchBox, Button, DataTable } from "@bluesilodev/timhutcomponents";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { useGetAllEvent } from "service/eventAPI";
import { adminAndSuperVisorRole, adminRole } from "utils/roleFromLocalStorage";

import { FiHeart } from "components/icons";
import DateDisplay from "./DateDisplay";
import TableMenu from "./TableMenu";

function Event() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [keyword, setKeyword] = useState("");

  const handleDirectPage = () => {
    navigate("/createEvent");
  };

  // DATA COLUMN
  let dataColumn = [
    {
      id: "nameEvent",
      accessorFn: (row) => row.nameEvent,
      header: () => <div>Name Of Events</div>,
      enableSorting: true,
      sortFunction: undefined,
    },
    {
      id: "location",
      accessorFn: (row) => {
        return row?.location?.locationName;
      },
      header: () => <div>Locations</div>,
      enableSorting: true,
      sortFunction: undefined,
    },
    {
      id: "startDate",
      accessorFn: (row) => row.startDate,
      cell: (param) => {
        const val = param.getValue();
        return <DateDisplay dateStr={val} />;
      },
      header: () => <div>Start Time</div>,
      enableSorting: true,
      sortFunction: undefined,
    },
    {
      id: "endDate",
      accessorFn: (row) => row.endDate,
      cell: (param) => {
        const val = param.getValue();
        return <DateDisplay dateStr={val} />;
      },
      header: () => <div>End Time</div>,
      enableSorting: true,
      sortFunction: undefined,
    },

    {
      id: "duration",
      accessorFn: (row) => row.duration,
      header: () => <div>Duration</div>,
      enableSorting: true,
      sortFunction: undefined,
    },
    {
      id: "isCompanyHoliday",
      accessorFn: (row) => row.isCompanyHoliday,
      cell: (param) => {
        const val = param.getValue();
        return <div>{val === true ? "yes" : "no"}</div>;
      },
      header: () => <div>Is This Company Holiday</div>,
      enableSorting: true,
      sortFunction: undefined,
    },
    {
      id: "note",
      accessorFn: (row) => row.note,
      header: () => <div>Notes</div>,
      enableSorting: true,
      sortFunction: undefined,
    },
  ];

  if (adminAndSuperVisorRole) {
    dataColumn.push({
      id: "action",
      disableSorting: true,
      header: () => "",
      accessorFn: (row) => {
        return row;
      },
      cell: (param) => {
        const data = param.getValue();

        return (
          <div className="cursor-pointer w-fit">
            <TableMenu data={data} />
          </div>
        );
      },
    });
  }

  const { data, isSuccess, refetch } = useGetAllEvent(keyword, {
    onSuccess: () => {
      queryClient.invalidateQueries("getEvent");
    },
    onError: (err) => {
      console.log("err : ", err);
    },
  });

  const inputRef = useRef(null);

  // FETCH DATA IF SUCCES GET API
  if (isSuccess) {
    refetch();
  }

  // CHANGE FOR FOCUS INPUT
  useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [keyword]);

  return (
    <div className="w-full overflow-auto pt-10  ">
      <div className="w-full flex justify-between">
        <SearchBox
          className={"w-[300px]"}
          value={keyword}
          ref={inputRef}
          onChange={(e) => setKeyword(e.target.value)}
          placeholder="Search by name event"
        />

        {adminRole && (
          <Button
            onClick={handleDirectPage}
            style={"solid"}
            className={"w-[200px]"}
            label={
              <div className="flex gap-2">
                <FiHeart color={"white"} />
                <div>Create Events</div>
              </div>
            }
          />
        )}
      </div>

      <div className="py-8">
        <DataTable
          columns={dataColumn}
          currentPage={1}
          data={!data?.data ? <div>Data not Found!</div> : data?.data}
          onChangePage={() => {}}
          onChangeRowsPerPage={() => {}}
          pagination
          rowsPerPage={10}
          title="Example DataTable"
          totalRows={100}
          maxHeight={370}
        />
      </div>
    </div>
  );
}

export default Event;
