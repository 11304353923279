import React from "react";

function CardTree({ data, handleShowModal }) {

  return (
    <div className="inline-block px-2 ">
      <div className="w-[252px] h-[104px] shadow-md rounded-md flex flex-col justify-center items-center gap-[3px] mt-8">
        <div className="flex justify-between w-full">
          <div
            style={{ backgroundColor: data.color }}
            className="h-[9px] w-full rounded-tl-md"
          ></div>

          <div
            className="border-white shadow-sm border-[5px] w-[75px] h-[75px] rounded-full flex justify-center items-center -mt-8 cursor-pointer hover:shadow-2xl"
            onClick={() => handleShowModal(data)}
          >
            <div
              style={{
                backgroundImage: `url(${data.photo})`,
              }}
              className={`bg-white w-[64px] h-[64px] rounded-full  bg-cover bg-center`}
            ></div>
          </div>

          <div
            style={{ backgroundColor: data.color }}
            className="h-[9px] w-full rounded-tr-md"
          ></div>
        </div>

        <h1 className="font-bold -mt-[5px] ">{data.firstName}</h1>

        <div className="bg-gray-300 rounded-xl flex justify-center items-center text-center truncate max-w-[120px] px-5 h-[20px] pb-[0.1px]">
          <h1 className="font-semibold text-[11px]">{data.department}</h1>
        </div>

        <div className="text-[11px] pb-1">{data.jobPosition}</div>
      </div>
    </div>
  );
}

export default CardTree;
