import clsx from "clsx";
import { twMerge } from "tailwind-merge";

export const getLocalStorage = () => {
  const payload = JSON.parse(localStorage.getItem("currentUser")) || "";
  const token = localStorage.getItem("accessToken") || "";
  const companyID = payload ? payload?.organizationID : null;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return {
    config,
    companyID,
  };
};

export const capitalize_first_letter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export function formatText(input) {
  if (!input) return ""; // Handle string kosong
  // Trim spasi di awal/akhir, lalu format teks
  return input
    .trim() // Menghapus spasi di awal dan akhir string
    .split(" ") // Memisahkan kalimat menjadi array kata
    .map(
      (word, index) =>
        index === 0
          ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Kata pertama huruf kapital
          : word.toLowerCase() // Kata lainnya huruf kecil
    )
    .join(" "); // Gabungkan kembali menjadi string
}

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}
