import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  adminAndSuperVisorRole,
  adminRole,
  superAdminRole,
} from "utils/roleFromLocalStorage";

import {
  OrganizationPage,
  ComunityPage,
  EmployeePage,
  AddEmployee,
  EditEmployee,
  DetailEmployee,
} from "pages/employee";

import {
  MyAccount,
  CompanyDetail,
  EmployeeStatus,
  Event,
  Locations,
  ResignNotice,
  CreateLocation,
  CreateEmployeeStatus,
  CreateEvent,
  EditLocation,
  EditEmployeeStatus,
  EditEvent,
  AdminSetting,
} from "pages/userSettings";

import {
  Nationality,
  Age,
  City,
  Department,
  Gender,
  Hobbies,
  Languages,
  LengthOf,
  MaritalStatus,
  Religion,
  Salary,
} from "pages/employee/comunityPage/comunityComp";

import {
  SuperAdminAddCompany,
  SuperAdminAddEmployee,
  SuperAdminEditCompany,
  SuperAdminEditEmployee,
  SuperAdminEmployee,
  SuperAdminOrganization,
  SuperAdminSetting,
} from "pages/superAdmin";

import {
  EmployeeLayOut,
  LayoutUserSetting,
  SuperAdminLayout,
} from "layout/layout";
// import HomeCoba from "coba/HomeCoba";

// import Auth from "components/Auth";

const AppRouter = () => {
  return (
    // <Auth>
    <Routes>
      {/* <Route path="/coba" element={<HomeCoba />} /> */}
      <Route path="/" element={<EmployeeLayOut />}>
        <Route path="/" element={<EmployeePage />} />
        {/* <Route index path="employee" element={<EmployeePage />} /> */}
        <Route path="organization" element={<OrganizationPage />} />

        <Route path="comunity" element={<ComunityPage />}>
          <Route path="nationality" element={<Nationality />} />
          <Route path="gender" element={<Gender />} />
          <Route path="age" element={<Age />} />
          <Route path="marital" element={<MaritalStatus />} />
          <Route path="religion" element={<Religion />} />
          <Route path="department" element={<Department />} />
          <Route path="city" element={<City />} />
          <Route path="hobbies" element={<Hobbies />} />
          <Route path="languages" element={<Languages />} />
          <Route path="salary" element={<Salary />} />
          <Route path="lengthOf" element={<LengthOf />} />
        </Route>

        {adminAndSuperVisorRole && (
          <>
            <Route path="addEmployee" element={<AddEmployee />} />
            <Route path="editEmployee/:uId" element={<EditEmployee />} />
          </>
        )}
        <Route path="/detailEmployee/:uId" element={<DetailEmployee />} />
      </Route>

      <Route path="/" element={<LayoutUserSetting />}>
        <Route index path="myAccount" element={<MyAccount />} />
        <Route path="companyDetail" element={<CompanyDetail />} />
        <Route path="employeeStatus" element={<EmployeeStatus />} />
        <Route path="event" element={<Event />} />
        <Route path="location" element={<Locations />} />
        <Route path="setting" element={<AdminSetting />} />

        <Route path="resignNotice" element={<ResignNotice />} />

        {adminRole && (
          <>
            <Route
              path="createEmployeeStatus"
              element={<CreateEmployeeStatus />}
            />
            <Route path="createEvent" element={<CreateEvent />} />
            <Route path="createLocation" element={<CreateLocation />} />
            <Route path="editLocation/:uId" element={<EditLocation />} />
            <Route
              path="editEmployeeStatus/:uId"
              element={<EditEmployeeStatus />}
            />
            <Route path="editEvent/:uId" element={<EditEvent />} />
          </>
        )}
      </Route>

      <Route path="/super/" element={<SuperAdminLayout />}>
        {superAdminRole && (
          <>
            <Route path="organization" element={<SuperAdminOrganization />} />
            <Route path="employee" element={<SuperAdminEmployee />} />
            <Route path="setting" element={<SuperAdminSetting />} />
            <Route path="addEmployee" element={<SuperAdminAddEmployee />} />
            <Route
              path="editEmployee/:uId"
              element={<SuperAdminEditEmployee />}
            />
            <Route path="addCompany" element={<SuperAdminAddCompany />} />
            <Route
              path="editCompany/:uId"
              element={<SuperAdminEditCompany />}
            />
          </>
        )}
      </Route>

      <Route
        path="*"
        element={<h1 className="text-2xl text-red-500">PAGE NOT FOUND</h1>}
      />
    </Routes>
    // </Auth>
  );
};

export default AppRouter;
