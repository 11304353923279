import React, { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import {
  useEditEmployeeStatus,
  useGetEmployeeStatusById,
} from "service/employeeStatusAPI";

import { editEmployeeStatusSchema } from "./editEmployeeStatusSchema";
import FormSharedEmployeeStatus from "pages/shared/formEmployeeStatus";
import { alertSuccess, alertError } from "utils/alert";

function EditEmployeeStatus() {
  const queryClient = useQueryClient();
  const payload = JSON.parse(localStorage.getItem("payload")) || "";
  const organizationID = payload && payload.payLoad.organizationID;
  const { uId } = useParams();
  const navigate = useNavigate();

  const { data, isSuccess } = useGetEmployeeStatusById(uId, {
    onSuccess: () => {
      queryClient.invalidateQueries("getEmployeeStatusById");
    },
    onError: (err) => {
      console.log("ERROR : ", err);
    },
  });

  const employeeStatusData = data?.data;

  useEffect(() => {
    if (isSuccess) {
      setValues({
        employeeType: employeeStatusData?.employeeType,
        postalCode: employeeStatusData?.postalCode,
        description: employeeStatusData?.description,
        isActive: employeeStatusData?.isActive,
      });
    }
  }, [isSuccess]);

  const { mutate, isPending: editPending } = useEditEmployeeStatus(uId, {
    onSuccess: () => {
      queryClient.invalidateQueries("getEmployeeStatus");
      alertSuccess("SUCCESS UPDATE DATA");
      navigate("/employeeStatus");
    },
    onError: () => {
      alertError("FAILED UPDATE DATA!");
    },
  });

  const initialValues = {
    organizationID: organizationID,
    employeeType: "",
    postalCode: "",
    description: "",
    isActive: false,
  };

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: editEmployeeStatusSchema,
    onSubmit: (val) => {
      mutate(val);
    },
  });

  if (editPending) {
    return <div>Loading...</div>;
  }

  return (
    <FormSharedEmployeeStatus
      errors={errors}
      handleBlur={handleBlur}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      touched={touched}
      values={values}
    />
  );
}

export default EditEmployeeStatus;
