import React, { useEffect, useState, memo } from "react";
import AppRouter from "routes";
import { BrowserRouter as Router } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";

import AlertMessage from "components/alertMessage";
import { setLoginReducer } from "store/userLoginSlice";

const LOCAL_STORAGE_KEYS = {
  ACCESS_TOKEN: "accessToken",
  CURRENT_ROLE: "currentRole",
  CURRENT_USER: "currentUser",
};

const App = memo(({ props }) => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const { currentUser, currentRole, token } = useSelector(
    (state) => state.loginSlice
  );

  const handleUserAuthentication = () => {
    const localStorageAccessToken = localStorage.getItem(
      LOCAL_STORAGE_KEYS.ACCESS_TOKEN
    );

    if (!localStorageAccessToken) {
      if (window.__POWERED_BY_QIANKUN__) {
        window.location.href = "/";
      }
      setIsLoading(false);
      return;
    }

    const user = jwtDecode(localStorageAccessToken);
    const localStorageCurrentRole = localStorage.getItem(
      LOCAL_STORAGE_KEYS.CURRENT_ROLE
    );
    const localStorageCurrentUser = localStorage.getItem(
      LOCAL_STORAGE_KEYS.CURRENT_USER
    );

    // Handle role management
    if (
      !localStorageCurrentRole ||
      !user?.role.includes(localStorageCurrentRole)
    ) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_ROLE, user?.role[0]);
    }

    // Handle user data management
    const stringifiedUser = JSON.stringify(user);
    if (
      !localStorageCurrentUser ||
      localStorageCurrentUser !== stringifiedUser
    ) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_USER, stringifiedUser);
    }

    dispatch(
      setLoginReducer({
        currentUser: user,
        token: localStorageAccessToken,
        currentRole: localStorageCurrentRole ?? currentRole ?? user?.role[0],
      })
    );

    setIsLoading(false);
  };

  useEffect(() => {
    handleUserAuthentication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof props?.setGlobalState === "function") {
      props.setGlobalState({
        currentUser,
        accessToken: token,
        currentRole,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.setGlobalState, currentUser, token, currentRole]);

  if (isLoading) return null;

  return (
    <React.Fragment>
      <Router basename={window.__POWERED_BY_QIANKUN__ ? "/employee" : "/"}>
        <AppRouter />
      </Router>
      <AlertMessage />
    </React.Fragment>
  );
});

export default App;
