import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { ModalConfirmation } from "@bluesilodev/timhutcomponents";

import { useDeleteMyCompany } from "service/companyAPI";
import { alertSuccess, alertError } from "utils/alert";
import {
  adminAndSuperVisorRole,
  superAdminRole,
} from "utils/roleFromLocalStorage";

import FillTableMenu from "components/FillTableMenu";

function TableMenu({ data }) {
  const [show, setShow] = useState(false);
  const [uIdData, setuIdData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleShow = () => {
    setShow(!show);
  };

  const onEditEmployee = (uId) => {
    navigate(`/super/editCompany/${uId}`);
  };

  // DELETE USER
  const { mutate, isPending } = useDeleteMyCompany({
    onSuccess: () => {
      queryClient.invalidateQueries("getUser");
      alertSuccess("SUCCESS DELETED DATA");
    },
    onError: () => {
      alertError("FAILED DELETE DATA!");
    },
  });

  const onDeleteEmployee = (uId) => {
    setuIdData(uId);
    setShowModal(true);
  };

  const confirmModal = () => {
    mutate({ companyID: uIdData });
    setShowModal(false);
    setShow(false);
  };

  const cancelModal = () => {
    setShowModal(false);
    setShow(false);
  };

  if (isPending) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="relative">
        <FillTableMenu
          admin={adminAndSuperVisorRole || superAdminRole}
          handleShow={handleShow}
          onDeleteEmployee={() => onDeleteEmployee(data?.uId)}
          onEditEmployee={() => onEditEmployee(data?.uId)}
          showSetting={show}
          setShow={setShow}
        />

        {showModal && (
          <ModalConfirmation
            headMessage="Are you sure want to delete it?"
            onClose={cancelModal}
            onConfirm={confirmModal}
          />
        )}
      </div>
    </>
  );
}

export default TableMenu;
