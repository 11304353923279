import { Accordion, DataTable } from "@bluesilodev/timhutcomponents";
import { useQueryClient } from "@tanstack/react-query";
import { useFormikContext } from "formik";
import { useState } from "react";
import { useSelector } from "react-redux";

import { useGetLeaveById, useGetLeaveGlobal } from "service/leaveApi";

import EmployeeMentDetailShare from "pages/shared/formUser/EmployeementDetailShare";
import { adminAndSuperVisorRole } from "utils/roleFromLocalStorage";

function EmployeeMentDetails() {
  const { currentUser } = useSelector((state) => state.loginSlice);
  const { values, handleBlur, handleChange, errors, touched, setFieldValue } =
    useFormikContext();

  const [addLeaveTypeModal, addLeaveTypeOpen] = useState(false);

  const uId = currentUser?.uId;
  const queryClient = useQueryClient();

  const { data: dataIndividual, isPending: loadingIndividu } = useGetLeaveById(
    uId,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getLeaveDataID");
      },
      onError: (err) => {
        console.log("ERR : ", err);
      },
    }
  );

  const { data: globalData, isPending: loadingGlobal } = useGetLeaveGlobal({
    onSuccess: () => {
      queryClient.invalidateQueries("globalLeave");
    },
    onError: (err) => {
      console.log("ERR: ", err);
    },
  });

  if (loadingIndividu || loadingGlobal) {
    return <div>loading...</div>;
  }

  const leaveData = dataIndividual?.data?.leaveTypeSettings
    ? dataIndividual.data.leaveTypeSettings
    : globalData?.data;

  // COLUMN
  const dataColumn = [
    {
      id: "leaveType",
      accessorFn: (row) => row.name,
      header: () => <span>Leave Type</span>,
      enableSorting: true,
    },
    {
      id: "amount",
      accessorFn: (row) => row.defaultAmount,
      header: () => <span>Default Amount</span>,
      enableSorting: true,
    },
    {
      id: "gainPermont",
      accessorFn: (row) => row.gainPerMonth,
      header: () => <span>Gain Per Month</span>,
      enableSorting: true,
    },
    {
      id: "expired",
      accessorFn: (row) => row.expiredCarryOver,
      header: () => <span>Expired Caryy Over</span>,
      enableSorting: true,
    },
    {
      id: "maxCarry",
      accessorFn: (row) => row?.maxCarryOver,
      header: () => <span>Max Carry Over</span>,
      enableSorting: true,
    },

    // {
    //   id: "action",
    //   disableSorting: true,
    //   header: () => "",
    //   accessorFn: (row) => {
    //     return row && row;
    //   },
    //   cell: (param) => {
    //     if (param) {
    //       const data = param.getValue();

    //       return (
    //         <>
    //           <ActionLeave data={data} />
    //         </>
    //       );
    //     }
    //   },
    // },
  ];

  return (
    <>
      <EmployeeMentDetailShare
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        touched={touched}
        values={values}
        setFieldValue={setFieldValue}
        disabled={!adminAndSuperVisorRole}
      />

      <div className="w-full">
        <Accordion
          title={"Leave Settings"}
          // icons={[
          //   {
          //     icon: (
          //       <div className="p-2 ">
          //         <Button
          //           type={"button"}
          //           className={"w-[200px] "}
          //           style={"solid"}
          //           label={
          //             <div className="w-full text-white flex items-center justify-center p-2 gap-2">
          //               <FiPlusSvg
          //                 className={"text-white w-[20px] h-[20px] "}
          //               />
          //               <h1>Add New Leave Type</h1>
          //             </div>
          //           }
          //           onClick={() => {
          //             addLeaveTypeOpen(true);
          //           }}
          //         />
          //       </div>
          //     ),
          //   },
          // ]}
          children={
            <div className="py-5">
              <div className="pt-5">
                <DataTable
                  columns={dataColumn}
                  data={leaveData ? leaveData : []}
                  pagination={true}
                  title="Employee Data"
                  maxHeight={450}
                />
              </div>
            </div>
          }
        />
        {/* <LeaveTypeAddModalForm
          addLeaveTypeModal={addLeaveTypeModal}
          addLeaveTypeOpen={addLeaveTypeOpen}
        /> */}
      </div>
    </>
  );
}

export default EmployeeMentDetails;
