import { useState } from "react";
import { ChevronLeft, ChevronRight } from "components/icons";
import NavComunity from "./NavComunity";

function HandleRightLeft() {
  // STATE FOR HANDLE POSITION
  const [positionX, setPositionX] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [lastMouseX, setLastMouseX] = useState(0);

  // HANDLE MOUSE
  const handleMouseDown = (e) => {
    setDragging(true);
    setLastMouseX(e.pageX);
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      e.preventDefault();
      const newPositionX = positionX + e.pageX - lastMouseX;

      // MAKESURE posisiX still in the 0 untill -300
      if (newPositionX > 0) {
        setPositionX(0); // if more than 0, set to 0
      } else if (newPositionX < -300) {
        setPositionX(-300); // if less than -300, set to -300
      } else {
        setPositionX(newPositionX); // if between 0 and -300, set to newPositionX
      }

      setLastMouseX(e.pageX);
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  return (
    <div className="flex w-full relative mt-5">
      <div className="absolute left-0 top-[32px] z-40 bg-white">
        <ChevronLeft />
      </div>
      
      <div className="w-screen cursor-grab z-0 absolute left-0 top-0 h-[80px] ">
        <div
          style={{
            transform: `translateX(${positionX}px) `,
            transition: dragging ? "none" : "transform 0.3s ease",
            cursor: dragging ? "grabbing" : "grab",
          }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          className="flex gap-5 w-full h-full p-5 items-center relative"
        >
          <NavComunity />
        </div>
      </div>
      <div className="absolute right-0 top-[32px] z-40 bg-white">
        <ChevronRight />
      </div>
    </div>
  );
}

export default HandleRightLeft;
