import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Sidebar, Header } from "@bluesilodev/timhutcomponents";

import { useGetAppQuery } from "service/commonAPI";
import { setLogoutReducer, setRoleReducer } from "store/userLoginSlice";
import { userLoginNow } from "utils/roleFromLocalStorage";

function LayoutForm({ allMenu, itemSetting, handleTitle }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser, currentRole } = useSelector((state) => state.loginSlice);

  const [apps, setApps] = useState([]);

  const { data: resApp, isSuccess: isSuccessApp, onError } = useGetAppQuery();

  useEffect(() => {
    if (isSuccessApp && resApp.data) {
      setApps(resApp.data);
    }
  }, [isSuccessApp, resApp]);

  const onClickLogout = () => {
    dispatch(setLogoutReducer());
    localStorage.removeItem("accessToken");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("currentRole");

    // using window as we used microservice frontend and need to redirect to workbench
    window.location.assign("/dashboard");
  };

  const onClickSwitch = () => {
    dispatch(setRoleReducer());
  };

  const onClickApp = (link) => {
    window.location.assign(link);
  };

  const onClickMyAccount = (link) => {
    // window.location.assign(`${link}/myAccount`);
    navigate(`/myAccount`);
  };

  console.log("ROLE: ", userLoginNow?.role);

  return (
    <div className="w-full flex justify-center items-center">
      <div className="flex h-screen w-full">
        <div className="w-[300px] ">
          <Sidebar menuItems={allMenu} onLogoClick={() => navigate("/")} />
        </div>

        <div className="flex flex-col w-full p-5 ">
          <div className="p-1">
            {apps.length > 0 && (
              <Header
                title={handleTitle()}
                apps={apps}
                // moduleDisabled={userLoginNow?.role?.includes("SuperAdmin")}
                // switchDisabled={userLoginNow?.role?.length < 2}
                // notificationDisabled={true}
                userData={{
                  name: userLoginNow?.userName,
                  role: currentRole,
                  language: "English",
                  // switchRole: currentRole === "Admin" ? "User" : "Admin",
                  switchRole:
                    currentRole === "Admin"
                      ? currentUser?.role.includes("Supervisor")
                        ? "Supervisor"
                        : "Employee"
                      : currentUser?.role.includes("Admin")
                      ? "Admin"
                      : currentRole,
                  image:
                    userLoginNow?.photo?.length > 0
                      ? currentUser?.photo[0].link
                      : "",
                }}
                onSwitch={onClickSwitch}
                onClickMyAccount={onClickMyAccount}
                onClickLogout={onClickLogout}
                onClickApp={onClickApp}
              />
            )}
          </div>

          <div className="w-full h-screen pt-10">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LayoutForm;
