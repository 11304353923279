import React from "react";
import { Accordion, InputSelect } from "@bluesilodev/timhutcomponents";
import SelectChoice from "components/SelectChoice";
import { useGetAllLanguage } from "service/commonAPI";
import { QueryClient } from "@tanstack/react-query";
function Languages({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  disabled = false,
}) {
  const queryClient = new QueryClient();
  const handleDelete = (positionIndex, setFieldValue, value) => {
    const data = value.filter((_, idx) => idx !== positionIndex);
    setFieldValue(`languages`, data);
  };

  const { data, isPending } = useGetAllLanguage({
    onSuccess: () => {
      queryClient.invalidateQueries("getLanguage");
    },
    onError: (err) => {
      console.log("err:", err);
    },
  });

  if (isPending) {
    return <h1>Loading...</h1>;
  }

  const listLanguage = data.data.map((item, _) => {
    return {
      label: item.language,
      value: item.language,
    };
  });

  return (
    <div className="w-full">
      <Accordion
        title={"Languages"}
        icons={[]}
        children={
          <div className="flex gap-3 w-full pt-3">
            <div className="w-full">
              <InputSelect
                title={"Languages"}
                options={listLanguage}
                disabled={disabled ? disabled : false}
                id={`languages`}
                value={values.languages[0] || ""}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  const updateLanguages = [...values.languages];
                  if (
                    !updateLanguages.includes(selectedValue) &&
                    selectedValue.length !== 0
                  ) {
                    // Periksa apakah nilai sudah ada dalam array
                    updateLanguages.push(selectedValue);
                    setFieldValue(`languages`, updateLanguages);
                  }
                }}
                onBlur={() => setFieldTouched(`languages`, true)}
                error={
                  errors.languages &&
                  touched.languages && (
                    <div className="text-red-500">{errors.languages}</div>
                  )
                }
              />
              <div className="flex gap-2 mt-2">
                {/* Mapping value languages */}
                {values.languages.map((position, positionIndex) => (
                  <div className="" key={positionIndex}>
                    <SelectChoice
                      title={position}
                      disabled={disabled}
                      handleDelete={() => {
                        const value = values.languages;
                        handleDelete(positionIndex, setFieldValue, value);
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}

export default Languages;
