import React from "react";
import { useFormikContext } from "formik";
import IdentifyDocShared from "pages/shared/formUser/IdentifyDocShared";

function IdentificationDoc() {
  const { values, handleChange, handleBlur, errors, touched, setFieldValue } =
    useFormikContext();

  return (
    <>
      <IdentifyDocShared
        values={values}
        errors={errors}
        handleChange={handleChange}
        fieldPhotoValue={"identificationDocument.attachments"}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        touched={touched}
        disabled={true}
      />
    </>
  );
}

export default IdentificationDoc;
