import React from "react";
import {
  Accordion,
  CheckBox,
  InputSelect,
  InputText,
  Button,
} from "@bluesilodev/timhutcomponents";

import AdditionalComponent from "./AdditionalComponent";
import MonthlyAllowance from "./MontlyAllowance";
import { adminRole, superAdminRole } from "utils/roleFromLocalStorage";

function PaymentDetailShared({
  values,
  handleBlur,
  handleChange,
  errors,
  touched,
  setFieldTouched,
  setFieldValue,
  disabled,
  hideAdditionalComponent = false,
  hideMonthlyAllowance = false,
}) {
  return (
    <div className="w-full py-5">
      <Accordion
        title={"Payment Details"}
        icons={[]}
        children={
          <div className="w-full flex flex-col gap-2 pt-3">
            <div className="flex gap-2">
              <InputSelect
                title={"Rate of Pay"}
                required={adminRole || superAdminRole || disabled}
                options={[
                  { label: "Monthly", value: "Monthly" },
                  { label: "Yearly", value: "Yearly" },
                ]}
                id="paymentDetail.rateOfPays"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.paymentDetail.rateOfPays}
                disabled={!(adminRole || superAdminRole) || (disabled && true)}
                error={
                  <div>
                    {errors.paymentDetail &&
                      errors.paymentDetail.rateOfPays &&
                      touched.paymentDetail &&
                      touched.paymentDetail.rateOfPays &&
                      errors.paymentDetail.rateOfPays}
                  </div>
                }
              />
              <InputSelect
                title={"Currency for Salary"}
                required={true}
                options={[
                  {
                    label: "Indonesia Rupiah (IDR)",
                    value: "Indonesia Rupiah (IDR)",
                  },
                  {
                    label: "Singapore Dollar (SGD)",
                    value: "Singapore Dollar (SGD)",
                  },
                ]}
                id="paymentDetail.currencies"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.paymentDetail.currencies}
                disabled={disabled ? disabled : false}
                error={
                  <div>
                    {errors.paymentDetail &&
                      errors.paymentDetail.currencies &&
                      touched.paymentDetail &&
                      touched.paymentDetail.currencies &&
                      errors.paymentDetail.currencies}
                  </div>
                }
              />
              <InputSelect
                title={"Payment Method"}
                required={true}
                options={[
                  { label: "Wise", value: "Wise" },
                  { label: "Paypal", value: "Paypal" },
                  { label: "Bank Transfer", value: "Bank Transfer" },
                  { label: "Virtual Account", value: "Virtual Account" },
                ]}
                id="paymentDetail.paymentMethods"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.paymentDetail.paymentMethods}
                disabled={disabled ? disabled : false}
                error={
                  <div>
                    {errors.paymentDetail &&
                      errors.paymentDetail.paymentMethods &&
                      touched.paymentDetail &&
                      touched.paymentDetail.paymentMethods &&
                      errors.paymentDetail.paymentMethods}
                  </div>
                }
              />
            </div>

            <div className="flex gap-2">
              <InputSelect
                title={"Bank"}
                required={true}
                disabled={disabled ? disabled : false}
                options={[
                  { label: "Bank", value: "Bank" },
                  { label: "Bank Mandiri", value: "Bank Mandiri" },
                  {
                    label: "Bank Central Asia (BCA)",
                    value: "Bank Central Asia (BCA)",
                  },
                  {
                    label: "Bank Rakyat Indonesia (BRI)",
                    value: "Bank Rakyat Indonesia (BRI)",
                  },
                  {
                    label: "Bank Negara Indonesia (BNI)",
                    value: "Bank Negara Indonesia (BNI)",
                  },
                  { label: "CIMB Niaga", value: "CIMB Niaga" },
                  { label: "Bank Danamon", value: "Bank Danamon" },
                  { label: "Panin Bank", value: "Panin Bank" },
                  { label: "Maybank Indonesia", value: "Maybank Indonesia" },
                  { label: "OCBC NISP", value: "OCBC NISP" },
                  { label: "HSBC Indonesia", value: "HSBC Indonesia" },
                  { label: "DBS Bank", value: "DBS Bank" },
                  { label: "OCBC Bank", value: "OCBC Bank" },
                  {
                    label: "United Overseas Bank (UOB)",
                    value: "United Overseas Bank (UOB)",
                  },
                  {
                    label: "Standard Chartered Bank",
                    value: "Standard Chartered Bank",
                  },
                  { label: "Citibank Singapore", value: "Citibank Singapore" },
                  { label: "HSBC Singapore", value: "HSBC Singapore" },
                  { label: "Maybank Singapore", value: "Maybank Singapore" },
                  { label: "RHB Bank", value: "RHB Bank" },
                  {
                    label: "Bank of China (Singapore)",
                    value: "Bank of China (Singapore)",
                  },
                  {
                    label: "CIMB Bank Singapore",
                    value: "CIMB Bank Singapore",
                  },
                ]}
                id="paymentDetail.banks"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.paymentDetail.banks}
                error={
                  <div>
                    {errors.paymentDetail &&
                      errors.paymentDetail.banks &&
                      touched.paymentDetail &&
                      touched.paymentDetail.banks &&
                      errors.paymentDetail.banks}
                  </div>
                }
              />
              <InputText
                title={"Number Of Account"}
                type={"number"}
                required={true}
                label={null}
                id="paymentDetail.numberAccount"
                disabled={disabled ? disabled : false}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.paymentDetail.numberAccount}
                error={
                  <div>
                    {errors.paymentDetail &&
                      errors.paymentDetail.numberAccount &&
                      touched.paymentDetail &&
                      touched.paymentDetail.numberAccount &&
                      errors.paymentDetail.numberAccount}
                  </div>
                }
              />
              <InputText
                title={"Bank Account Holder's Name"}
                required={true}
                label={null}
                id="paymentDetail.accountName"
                disabled={disabled ? disabled : false}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.paymentDetail.accountName}
                error={
                  <div>
                    {errors.paymentDetail &&
                      errors.paymentDetail.accountName &&
                      touched.paymentDetail &&
                      touched.paymentDetail.accountName &&
                      errors.paymentDetail.accountName}
                  </div>
                }
              />
            </div>

            <div className="flex gap-2">
              <InputText
                title={"Basic Salary"}
                type={"number"}
                required={true}
                label={null}
                disabled={disabled ? disabled : false}
                id="paymentDetail.basicSalary"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.paymentDetail.basicSalary}
                error={
                  <div>
                    {errors.paymentDetail &&
                      errors.paymentDetail.basicSalary &&
                      touched.paymentDetail &&
                      touched.paymentDetail.basicSalary &&
                      errors.paymentDetail.basicSalary}
                  </div>
                }
              />

              <div className="w-full flex justify-start rounded-md">
                <div className=" flex gap-3 pl-2">
                  <CheckBox
                    label={"Allow For Overtime"}
                    value={values.paymentDetail.allowOvertime}
                    nameInput={"paymentDetail.allowOvertime"}
                    disabled={disabled}
                    onChange={(v) =>
                      setFieldValue("paymentDetail.allowOvertime", v)
                    }
                  />
                </div>
              </div>

              <div className="w-full"></div>
            </div>
          </div>
        }
      />

      <Accordion title={"Statutory Details"} icons={[]}>
        <div className="w-full flex flex-col gap-2 pt-3">
          <div className="flex gap-2">
            <InputSelect
              title={"Group Contribution"}
              disabled={disabled ? disabled : false}
              // required={false}
              options={[
                { label: "CDAC", value: "CDAC" },
                { label: "ECF", value: "ECF" },
                { label: "MBMF", value: "MBMF" },
                { label: "SINDA", value: "SINDA" },
              ]}
              id="paymentDetail.groupContribution"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.paymentDetail.groupContribution}
              error={
                <div>
                  {errors.paymentDetail &&
                    errors.paymentDetail.groupContribution &&
                    touched.paymentDetail &&
                    touched.paymentDetail.groupContribution &&
                    errors.paymentDetail.groupContribution}
                </div>
              }
            />

            <InputText
              title={"CPF Number Account"}
              type={"number"}
              // required={false}
              label={null}
              id="paymentDetail.cpfAccountNumber"
              disabled={disabled ? disabled : false}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.paymentDetail.cpfAccountNumber}
              error={
                <div>
                  {errors.paymentDetail &&
                    errors.paymentDetail.cpfAccountNumber &&
                    touched.paymentDetail &&
                    touched.paymentDetail.cpfAccountNumber &&
                    errors.paymentDetail.cpfAccountNumber}
                </div>
              }
            />
          </div>
          <div className="flex gap-3 pt-3">
            <div className="w-full">
              <CheckBox
                label={"SDL Applicable"}
                value={values.paymentDetail.sdlApplicable}
                nameInput={"paymentDetail.sdlApplicable"}
                disabled={disabled}
                onChange={(v) =>
                  setFieldValue("paymentDetail.sdlApplicable", v)
                }
              />
            </div>
            <div className="w-full">
              <CheckBox
                label={"FWL Applicable"}
                value={values.paymentDetail.fwlApplicable}
                nameInput={"paymentDetail.fwlApplicable"}
                disabled={disabled}
                onChange={(v) =>
                  setFieldValue("paymentDetail.fwlApplicable", v)
                }
              />
            </div>
          </div>
        </div>
      </Accordion>

      {!hideAdditionalComponent && (
        <AdditionalComponent
          errors={errors}
          touched={touched}
          values={values}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          disabled={disabled}
        />
      )}

      {!hideMonthlyAllowance && (
        <MonthlyAllowance
          errors={errors}
          touched={touched}
          values={values}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          disabled={disabled}
        />
      )}
    </div>
  );
}

export default PaymentDetailShared;
