import * as Yup from "yup";

const validationEditSchema = Yup.object().shape({
  employeeID: Yup.string().required("Employee ID is required"),
  organizationID: Yup.string().required("Organization ID is required"),
  firstName: Yup.string()
    .matches(
      /^[A-Za-z0-9 _-]+$/,
      "Only letters, numbers, spaces, underscores, and dashes are allowed"
    )
    .required("First Name is required"),
  lastName: Yup.string()
    .matches(
      /^[A-Za-z0-9 _-]+$/,
      "Only letters, numbers, spaces, underscores, and dashes are allowed"
    )
    .required("Last Name is required"),
  userName: Yup.string()
    .matches(
      /^[A-Za-z0-9 _-]+$/,
      "Only letters, numbers, spaces, underscores, and dashes are allowed"
    )
    .required("Username is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string(),
  confirmPassword: Yup.string().when("password", (password, schema) => {
    return password
      ? schema.oneOf([Yup.ref("password"), null], "Password must match")
      : schema;
  }),
  countryPhoneNumber: Yup.number().required("Country Number is required"),
  phoneNumber: Yup.number().required("Phone Number is required"),
  photo: Yup.mixed()
    .test("fileSize", "The file is too large", (value) => {
      if (value === undefined || value === "" || value.length === 0)
        return true;
      if (typeof value !== "string") {
        // Jika ada file, lanjutkan dengan logika utama test
        return value.size <= 2000000; // Atau kembalikan nilai yang sesuai dengan logika Anda
      }
      // Jika tidak ada file, kembalikan true karena tidak ada file untuk diperiksa
      return true;
    })
    .test(
      "type",
      "Only the following formats are accepted: .jpeg, .jpg .png",
      (value) => {
        if (value === undefined || value === "" || value.length === 0)
          return true;
        if (typeof value !== "string") {
          return (
            value &&
            (value.type === "image/jpeg" ||
              value.type === "image/png" ||
              value.type === "image/jpg")
          );
        }

        return true;
      }
    ),
  gender: Yup.string().required("Gender is Required"),
  bloodTypes: Yup.string().required("Blood Type is Required"),
  religions: Yup.string().required("Religion is Required"),
  dateOfBirth: Yup.string()
    .required("Date of Birth is required")
    .test("notNaN", "Date of birth is Required", (val) => {
      if (val === undefined) {
        return false;
      }
      return true;
    }),
  maritalStatus: Yup.string().required("Marital Status is Required"),
  address: Yup.string(),
  country: Yup.string(),
  postalCode: Yup.number(),
  role: Yup.array().of(Yup.string()).required("Job Level is required"),

  employeementDetail: Yup.object().shape({
    employeeStatus: Yup.string().required("Employee Status is required"),
    locations: Yup.string().required("Location is required"),
    joinDate: Yup.string()
      .required("Join Date is required")
      .test(
        "more than",
        "Start Date must be less than End Date",
        (val, context) => {
          const contextDate = context.parent.endDate;

          if (contextDate && val) {
            const [startDay, startMonth, startYear] = val.split("/");
            const joinDate = new Date(startYear, startMonth - 1, startDay);

            const [endDay, endMonth, endYear] = contextDate.split("/");
            const endDate = new Date(endYear, endMonth - 1, endDay);

            return endDate > joinDate;
          }
          return true;
        }
      ),

    endDate: Yup.string()
      .required("End Date is required")
      .test(
        "is-greater",
        "End Date must be greater than Start Date",
        function (val, context) {
          // 'this' refers to the entire object being validated
          const contextDate = context.parent.joinDate;

          if (contextDate && val) {
            const [startDay, startMonth, startYear] = val.split("/");
            const endDate = new Date(startYear, startMonth - 1, startDay);

            const [endDay, endMonth, endYear] = contextDate.split("/");
            const joinDate = new Date(endYear, endMonth - 1, endDay);

            return endDate > joinDate;
          }
          return true;
        }
      ),
    departments: Yup.string().required("Departments is required"),
    // leaveDetail: Yup.object().shape({
    //     // leave: Yup.boolean(),
    //     leaveSettings: Yup.array().of(Yup.object().shape({
    //         leaveSettingID: Yup.string().required('Leave Setting ID is required'),
    //         defaultAmount: Yup.number().required('Default Amount is required'),
    //         gainPerMonth: Yup.number().required('Gain Per Month is required'),
    //         expiredCarryOver: Yup.number().required('Expired Carry Over is required'),
    //         maxCarryOver: Yup.number().required('Max Carry Over is required'),
    //     })),
    // }),

    jobPosition: Yup.string().required("Job Position is required"),
  }),

  hobbies: Yup.array().of(Yup.string()),
  skills: Yup.array().of(Yup.string()),
  languages: Yup.array().of(Yup.string()),

  identificationDocument: Yup.object().shape({
    identificationType: Yup.string().required(
      "Identification Type is required"
    ),
    identityExpiredDate: Yup.string().required(
      "Identity Expired Date is required"
    ),
    nationality: Yup.string().required("Nationality is required"),
    identityNumber: Yup.number().required("Identity Number is required"),
    // isPermanentDate: Yup.boolean(),
    covid19VaksinationStatus: Yup.string(),
    attachments: Yup.mixed()
      .test("fileSize", "The file is too large", (value) => {
        if (value === undefined || value === "" || value.length === 0)
          return true;
        if (typeof value !== "string") {
          // Jika ada file, lanjutkan dengan logika utama test
          return value.size <= 2000000; // Atau kembalikan nilai yang sesuai dengan logika Anda
        }
        // Jika tidak ada file, kembalikan true karena tidak ada file untuk diperiksa
        return true;
      })
      .test(
        "type",
        "Only the following formats are accepted: .jpeg, .jpg .png",
        (value) => {
          if (value === undefined || value === "" || value.length === 0)
            return true;
          if (typeof value !== "string") {
            return (
              value &&
              (value.type === "image/jpeg" ||
                value.type === "image/png" ||
                value.type === "image/jpg")
            );
          }

          return true;
        }
      ),
  }),

  paymentDetail: Yup.object().shape({
    rateOfPays: Yup.string().required("Rate of Pays is required"),
    banks: Yup.string().required("Bank is required"),
    basicSalary: Yup.number().required("Basic Salary is required"),
    currencies: Yup.string().required("CurrentCies is required"),
    numberAccount: Yup.number().required("Number Account is required"),
    // allowOvertime: Yup.boolean(),
    paymentMethods: Yup.string().required("Payment Methode is required"),
    accountName: Yup.string().required("Account Name is required"),
    groupContribution: Yup.string(),
    cpfAccountNumber: Yup.string(),
    sdlApplicable: Yup.boolean(),
    fwlApplicable: Yup.boolean(),
    additionalComponents: Yup.array().of(
      Yup.object().shape({
        componentName: Yup.string().required("Component Name is Required"),
        componentType: Yup.string().required("Component Type is Required"),
        amountType: Yup.string().required("Amount Type is Required"),
        amount: Yup.number().required("Amount is Required"),
      })
    ),
    monthlyAllowances: Yup.array().of(
      Yup.object().shape({
        typeOfAllowance: Yup.string().required("Type Of Allowance is Required"),
        amount: Yup.number().required("Amount is Required"),
      })
    ),
  }),
});

export default validationEditSchema;
