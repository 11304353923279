import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";

import "../../../../node_modules/flag-icons/css/flag-icons.min.css";
import { useSelector, useDispatch } from "react-redux";
import {
  Address,
  Education,
  Hobbies,
  Languages,
  PersonalInformation,
  ProfilePicture,
  Skills,
  UserCredential,
} from "pages/shared/formUser/personalInfo";
import { setDataEditStudy } from "store/employeeSlice";

function PersonalInfo({ countries, canEditPassword }) {
  const dispatch = useDispatch();
  const {
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  const { dataEditStudy } = useSelector((state) => state.employeeSlice);

  const setFormikValRedux = (updatedFormikVal) => {
    dispatch(setDataEditStudy(updatedFormikVal));
  };

  return (
    <div className="w-full flex flex-col gap-4 overflow-y-auto h-max py-5">
      <ProfilePicture
        values={values}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        fieldPhoto={"photo"}
      />
      <PersonalInformation
        values={values}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
      />

      <UserCredential
        values={values}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
        disabledPassword={true}
      />

      <Education formikVal={dataEditStudy} setFormikVal={setFormikValRedux} />

      <Address
        values={values}
        countries={countries}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />
      <Hobbies
        values={values}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
      />
      <Skills
        values={values}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
      />
      <Languages
        values={values}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
      />
    </div>
  );
}

export default PersonalInfo;
