import React from "react";
import { Accordion, InputSelect } from "@bluesilodev/timhutcomponents";
import { ChevronSvg } from "components/icons";
import SelectChoice from "components/SelectChoice";
import { useGetSettingByDefault } from "service/settingAPI";

function Skills({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  disabled = false,
}) {
  const { data, isPending: loadingUpdate } = useGetSettingByDefault({
    onSuccess: () => {},
    onError: () => {},
  });

  const handleDelete = (positionIndex, setFieldValue, value) => {
    const data = value.filter((item, idx) => idx !== positionIndex);
    setFieldValue(`skills`, data);
  };

  return (
    <div className="w-full">
      <Accordion
        title={"Skills"}
        icons={[]}
        children={
          <div className="flex gap-3 w-full pt-3">
            <div className="w-full">
              <InputSelect
                title={"Skills"}
                options={[
                  ...(data?.data?.skills?.map((val) => ({
                    label: val,
                    value: val,
                  })) || []),

                  /*
                  { label: "SQL", value: "SQL" },
                  { label: "Creative", value: "Creative" },
                  { label: "HTML", value: "HTML" },
                  { label: "CSS", value: "CSS" },
                  { label: "Innovate", value: "Innovate" },
                  { label: "Python", value: "Python" },
                  { label: "Design", value: "Design" },
                  */
                  /*
                  { label: "Publick Speaking", value: "Publick Speaking" },
                  { label: "Communication", value: "Communication" },
                  { label: "Leadership", value: "Leadership" },
                  { label: "Teamwork", value: "Teamwork" },
                  { label: "Problem-solving", value: "Problem-solving" },
                  { label: "Time management", value: "Time management" },
                  { label: "Critical thinking", value: "Critical thinking" },
                  { label: "Technical skills", value: "Technical skills" },
                  { label: "Project management", value: "Project management" },
                  { label: "Adaptability", value: "Adaptability" },
                  { label: "Creativity", value: "Creativity" },
                  { label: "Negotiation", value: "Negotiation" },
                  { label: "Analytical skills", value: "Analytical skills" },
                  { label: "Customer service", value: "Customer service" },
                  { label: "Public speaking", value: "Public speaking" },
                  { label: "Writing", value: "Writing" },
                  { label: "Research", value: "Research" },
                  { label: "Marketing", value: "Marketing" },
                  { label: "Data analysis", value: "Data analysis" },
                  { label: "Networking", value: "Networking" },
                  { label: "Financial management", value: "Financial management" },
                  { label: "Decision making", value: "Decision making" },
                  { label: "Emotional intelligence", value: "Emotional intelligence" },
                  { label: "Multitasking", value: "Multitasking" },
                  { label: "Strategic planning", value: "Strategic planning" },
                  { label: "Conflict resolution", value: "Conflict resolution" },
                  */
                ]}
                disabled={disabled ? disabled : false}
                id={`skills`}
                value={values.skills[0] || ""}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  const updateSkills = [...values.skills];
                  if (
                    !updateSkills.includes(selectedValue) &&
                    selectedValue.length !== 0
                  ) {
                    // Periksa apakah nilai sudah ada dalam array
                    updateSkills.push(selectedValue);
                    setFieldValue(`skills`, updateSkills);
                  }
                }}
                onBlur={() => setFieldTouched(`skills`, true)}
                error={
                  errors.skills &&
                  touched.skills && (
                    <div className="text-red-500">{errors.skills}</div>
                  )
                }
              />
              <div className="flex gap-2 mt-2">
                {/* Mapping value Hobbies */}
                {values.skills.map((position, positionIndex) => (
                  <div className="" key={positionIndex}>
                    <SelectChoice
                      title={position}
                      disabled={disabled}
                      handleDelete={() => {
                        const value = values.skills;
                        handleDelete(positionIndex, setFieldValue, value);
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}

export default Skills;
