import React from "react";
import {
  Accordion,
  Button,
  InputSelect,
  InputText,
} from "@bluesilodev/timhutcomponents";
import { FieldArray } from "formik";

import { FiPlusCircle, DeleteSvg } from "../../../../components/icons";
import { useGetAllPayrollComponent } from "service/payrollApi";

function AdditionalComponent({
  values,
  errors,
  touched,
  setFieldTouched,
  setFieldValue,
  disabled = false,
}) {
  const { data, isSuccess, refetch } = useGetAllPayrollComponent({
    onSuccess: () => {},
    onError: (err) => {
      console.log("err : ", err);
    },
  });
  return (
    <div className="py-5">
      <Accordion
        title={"Additional Component"}
        icons={[]}
        children={
          <FieldArray name="paymentDetail.additionalComponents">
            {({ push, remove }) => (
              <React.Fragment>
                {values.paymentDetail.additionalComponents.map(
                  (item, index) => (
                    <div
                      key={index}
                      className="w-full flex flex-col gap-2 pt-3"
                    >
                      <div className="flex gap-2 w-full">
                        <div className="w-[60%] flex gap-3">
                          <InputSelect
                            title={"Component Name"}
                            required={true}
                            disabled={disabled}
                            options={[
                              ...(data?.data
                                ?.filter(
                                  (valAll) =>
                                    !values.paymentDetail.additionalComponents.some(
                                      (val) => valAll.name === val.componentName
                                    )
                                )
                                ?.map((value) => ({
                                  label: value.name,
                                  value: value.name,
                                })) || []),
                              ...(item.componentName
                                ? [
                                    {
                                      label: item.componentName,
                                      value: item.componentName,
                                    },
                                  ]
                                : []),
                            ]}
                            id={`paymentDetail.additionalComponents.${index}.componentName`}
                            value={
                              values.paymentDetail.additionalComponents[index]
                                .componentName
                            }
                            onChange={(e) => {
                              setFieldValue(
                                `paymentDetail.additionalComponents.${index}.componentName`,
                                e.target.value
                              );
                              const dataFind = data?.data?.find(
                                (val) => val.name === e.target.value
                              );
                              if (dataFind) {
                                setFieldValue(
                                  `paymentDetail.additionalComponents.${index}.componentType`,
                                  dataFind.type
                                );
                                setFieldValue(
                                  `paymentDetail.additionalComponents.${index}.amountType`,
                                  dataFind.amountType
                                );
                                setFieldValue(
                                  `paymentDetail.additionalComponents.${index}.amount`,
                                  dataFind.amount
                                );
                              }
                            }}
                            onBlur={() =>
                              setFieldTouched(
                                `paymentDetail.additionalComponents.${index}.componentName`,
                                true
                              )
                            }
                            error={
                              errors.paymentDetail &&
                              errors.paymentDetail.additionalComponents &&
                              errors.paymentDetail.additionalComponents[
                                index
                              ] &&
                              errors.paymentDetail.additionalComponents[index]
                                .componentName &&
                              touched.paymentDetail &&
                              touched.paymentDetail.additionalComponents &&
                              touched.paymentDetail.additionalComponents[
                                index
                              ] &&
                              touched.paymentDetail.additionalComponents[index]
                                .componentName && (
                                <div className="text-red-500">
                                  {
                                    errors.paymentDetail.additionalComponents[
                                      index
                                    ].componentName
                                  }
                                </div>
                              )
                            }
                          />
                          <InputSelect
                            title={"Component Type"}
                            disabled={disabled}
                            required={true}
                            options={[
                              { label: "Earning", value: "earning" },
                              { label: "Deduction", value: "deduction" },
                            ]}
                            id={`paymentDetail.additionalComponents.${index}.componentType`}
                            value={
                              values.paymentDetail.additionalComponents[index]
                                .componentType
                            }
                            onChange={(e) => {
                              setFieldValue(
                                `paymentDetail.additionalComponents.${index}.componentType`,
                                e.target.value
                              );
                            }}
                            onBlur={() =>
                              setFieldTouched(
                                `paymentDetail.additionalComponents.${index}.componentType`,
                                true
                              )
                            }
                            error={
                              errors.paymentDetail &&
                              errors.paymentDetail.additionalComponents &&
                              errors.paymentDetail.additionalComponents[
                                index
                              ] &&
                              errors.paymentDetail.additionalComponents[index]
                                .componentType &&
                              touched.paymentDetail &&
                              touched.paymentDetail.additionalComponents &&
                              touched.paymentDetail.additionalComponents[
                                index
                              ] &&
                              touched.paymentDetail.additionalComponents[index]
                                .componentType && (
                                <div className="text-red-500">
                                  {
                                    errors.paymentDetail.additionalComponents[
                                      index
                                    ].componentType
                                  }
                                </div>
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="flex gap-2 w-full">
                        <div className="w-[60%] flex gap-3">
                          <InputSelect
                            title={"Amount Type"}
                            disabled={disabled}
                            required={true}
                            options={[
                              { label: "Percentage", value: "percentage" },
                              { label: "Amount Fixed", value: "fixed" },
                            ]}
                            id={`paymentDetail.additionalComponents.${index}.amountType`}
                            value={
                              values.paymentDetail.additionalComponents[index]
                                .amountType
                            }
                            onChange={(e) => {
                              setFieldValue(
                                `paymentDetail.additionalComponents.${index}.amountType`,
                                e.target.value
                              );
                            }}
                            onBlur={() =>
                              setFieldTouched(
                                `paymentDetail.additionalComponents.${index}.amountType`,
                                true
                              )
                            }
                            error={
                              errors.paymentDetail &&
                              errors.paymentDetail.additionalComponents &&
                              errors.paymentDetail.additionalComponents[
                                index
                              ] &&
                              errors.paymentDetail.additionalComponents[index]
                                .amountType &&
                              touched.paymentDetail &&
                              touched.paymentDetail.additionalComponents &&
                              touched.paymentDetail.additionalComponents[
                                index
                              ] &&
                              touched.paymentDetail.additionalComponents[index]
                                .amountType && (
                                <div className="text-red-500">
                                  {
                                    errors.paymentDetail.additionalComponents[
                                      index
                                    ].amountType
                                  }
                                </div>
                              )
                            }
                          />
                          <InputText
                            type={"number"}
                            label={null}
                            disabled={disabled}
                            title={"Amount"}
                            id={`paymentDetail.additionalComponents.${index}.amount`}
                            value={
                              values.paymentDetail.additionalComponents[index]
                                .amount
                            }
                            onChange={(e) => {
                              setFieldValue(
                                `paymentDetail.additionalComponents.${index}.amount`,
                                e.target.value
                              );
                            }}
                            onBlur={() =>
                              setFieldTouched(
                                `paymentDetail.additionalComponents.${index}.amount`,
                                true
                              )
                            }
                            error={
                              errors.paymentDetail &&
                              errors.paymentDetail.additionalComponents &&
                              errors.paymentDetail.additionalComponents[
                                index
                              ] &&
                              errors.paymentDetail.additionalComponents[index]
                                .amount &&
                              touched.paymentDetail &&
                              touched.paymentDetail.additionalComponents &&
                              touched.paymentDetail.additionalComponents[
                                index
                              ] &&
                              touched.paymentDetail.additionalComponents[index]
                                .amount && (
                                <div className="text-red-500">
                                  {
                                    errors.paymentDetail.additionalComponents[
                                      index
                                    ].amount
                                  }
                                </div>
                              )
                            }
                          />
                        </div>
                        {!disabled && (
                          <div
                            onClick={() => remove(index)}
                            className={`rounded-full cursor-pointer border-[1px] border-black h-[60px] w-[60px] flex justify-center items-center hover:bg-slate-300 ${
                              index > -1 ? "block" : "hidden"
                            }`}
                          >
                            <DeleteSvg />
                          </div>
                        )}
                      </div>
                      <hr />
                    </div>
                  )
                )}
                {!disabled && (
                  <Button
                    type={"button"}
                    onClick={() =>
                      push({
                        componentName: "",
                        componentType: "",
                        amountType: "",
                        amount: "",
                      })
                    }
                    label={
                      <div className="flex gap-2">
                        <FiPlusCircle color={"#A3A3AB"} />
                        <div className="text-white">
                          Add Additional Component
                        </div>
                      </div>
                    }
                    // backgroundColor={"#F1F1F1"}
                    className={"w-[60%] mt-2 btn-secondary-solid"}
                    style="solid"
                  />
                )}
              </React.Fragment>
            )}
          </FieldArray>
        }
      />
    </div>
  );
}

export default AdditionalComponent;
