import { Formik } from "formik";
import React, { useState } from "react";
import { Button, BreadcrumbSteps } from "@bluesilodev/timhutcomponents";

import { ChevronRight, ChevronLeft, FiSaveSvg } from "components/icons";
import FormErrorNotificationShared from "./FormErrorNotificationShared";

function EditFormShared({
  handleSubmitForm,
  validationSchema,
  children,
  currentStep,
  setCurrentStep,
  initialValues,
  handlePlus,
  isDetailData,
}) {
  const [isHoveredIcon, setIsHoveredIcon] = useState(false);
  const [isBackHover, setIsBackHover] = useState(false);

  const handleMinus = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const nextMouseEnter = () => setIsHoveredIcon(true);
  const nextMouseLeave = () => setIsHoveredIcon(false);
  const backMouseEnter = () => setIsBackHover(true);
  const backMouseLeave = () => setIsBackHover(false);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmitForm}
    >
      {(props) => {
        const {
          handleSubmit,
          setSubmitting,
          setFieldTouched,
          values,
          setTouched,
          errors,
          isValid,
        } = props;

        return (
          <>
            <FormErrorNotificationShared />

            <div className="w-full mt-4">
              <div className="w-full bg-white z-[30] sticky top-0 pb-2">
                <div className="flex items-center pt-5 px-8">
                  {/* Back Button */}
                  {currentStep > 1 && (
                    <div>
                      <Button
                        onClick={handleMinus}
                        onMouseEnter={backMouseEnter}
                        onMouseLeave={backMouseLeave}
                        type="button"
                        className="w-[150px]"
                        label={
                          <div className="w-full flex items-center justify-center gap-3">
                            <ChevronLeft
                              color={isBackHover ? "white" : "#DD7224"}
                            />
                            <h1>Back</h1>
                          </div>
                        }
                      />
                    </div>
                  )}

                  {/* Breadcrumb */}
                  <div className="flex justify-center items-center w-full">
                    <div className="w-[800px]">
                      <BreadcrumbSteps
                        currentStep={currentStep}
                        setCurrentStep={(step) => {
                          if (step < currentStep) {
                            setCurrentStep(step);
                          } else {
                            if (!isValid) {
                              setSubmitting(true);
                              return;
                            }

                            setCurrentStep(step);
                          }
                        }}
                        steps={[
                          "Personal Info",
                          "Employment Details",
                          "Identification Doc",
                          "Payment Details",
                        ]}
                      />
                    </div>
                  </div>

                  {/* Save Button */}
                  {!isDetailData && (
                    <div>
                      <Button
                        style="solid"
                        className="w-[150px] mr-4"
                        type="submit"
                        onClick={handleSubmit}
                        label={
                          <div className="w-full flex items-center justify-center gap-3">
                            <FiSaveSvg />
                            <h1>Save</h1>
                          </div>
                        }
                      />
                    </div>
                  )}

                  {/* Next Button */}
                  {currentStep <= 3 && (
                    <div>
                      <Button
                        onClick={() => {
                          if (!isValid) {
                            setSubmitting(true);
                          }

                          handlePlus(
                            values,
                            setTouched,
                            setFieldTouched,
                            errors
                          );
                        }}
                        onMouseEnter={nextMouseEnter}
                        onMouseLeave={nextMouseLeave}
                        className="w-[150px]"
                        type="button"
                        label={
                          <div className="w-full flex items-center justify-center gap-3">
                            <h1>Next</h1>
                            <ChevronRight
                              color={isHoveredIcon ? "white" : "#DD7224"}
                            />
                          </div>
                        }
                      />
                    </div>
                  )}
                </div>
              </div>

              {/* Content */}
              <div className="w-full pt-6">
                <div className="w-full h-[calc(100vh-251px)] overflow-y-auto">
                  {children}
                </div>
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
}

export default EditFormShared;
