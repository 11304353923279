import React from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { useCreateEmployeeStatus } from "service/employeeStatusAPI";

import FormSharedEmployeeStatus from "pages/shared/formEmployeeStatus";
import { employeeStatusSchema } from "./employeeStatusSchema";
import { alertSuccess, alertError } from "utils/alert";
import { formatText } from "utils/common";

function CreateEmployeeStatus() {
  const queryClient = useQueryClient();
  // const payload = JSON.parse(localStorage.getItem("payload")) || "";
  const { currentUser } = useSelector((state) => state.loginSlice);
  // const organizationID = payload && payload.payLoad.organizationID;
  const organizationID = currentUser ? currentUser.organizationID : "";
  const navigate = useNavigate();

  const { mutate, isPending } = useCreateEmployeeStatus({
    onSuccess: () => {
      queryClient.invalidateQueries("getEmployeeStatus");
      alertSuccess("Create Data Success!");
      navigate("/employeeStatus");
    },
    onError: () => {
      alertError("Something Wrong...");
    },
  });

  const { handleBlur, handleChange, handleSubmit, values, errors, touched } =
    useFormik({
      initialValues: {
        organizationID: organizationID,
        employeeType: "",
        description: "",
        isActive: false,
      },
      validationSchema: employeeStatusSchema,
      onSubmit: (val, { resetForm }) => {
        const allVal = {
          ...val,
          employeeType: formatText(val.employeeType),
        };

        mutate(allVal);
        resetForm();
      },
    });

  if (isPending) {
    return <div>Loading...</div>;
  }

  return (
    <FormSharedEmployeeStatus
      errors={errors}
      handleBlur={handleBlur}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      touched={touched}
      values={values}
    />
  );
}

export default CreateEmployeeStatus;
