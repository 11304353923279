import React from "react";
import { Accordion, Button, InputText } from "@bluesilodev/timhutcomponents";
import { FieldArray } from "formik";
import { DeleteSvg, FiPlusCircle, FiSaveSvg } from "components/icons";

function FormSetting({
  values,
  errors,
  touched,
  handleSubmit,
  setFieldValue,
  setFieldTouched,
  isSuperAdmin,
  disabled,
  maxHeight,
}) {
  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit}
      className={`overflow-y-auto max-h-[calc(100vh-123px)]`}
    >
      {!disabled && (
        <div className="w-full flex justify-end pt-5">
          <Button
            style="solid"
            className={"w-[200px]"}
            type="submit"
            label={
              <div className="flex gap-2">
                <FiSaveSvg />
                <div>Save</div>
              </div>
            }
          />
        </div>
      )}
      {isSuperAdmin && (
        <React.Fragment>
          <div className="py-5">
            <Accordion
              title={"Employee Head Count"}
              icons={[]}
              children={
                <FieldArray name="employeeHeadCount">
                  {({ push, remove }) => (
                    <div className="w-full flex flex-col gap-2 pt-3">
                      {typeof errors.employeeHeadCount == "string" && (
                        <div className="text-red-500">
                          {errors.employeeHeadCount}
                        </div>
                      )}
                      {values.employeeHeadCount.map((item, index) => (
                        <div key={index} className="flex gap-2 w-full">
                          <div className="w-[60%] flex gap-3">
                            <InputText
                              label={null}
                              title={"Employee Head Count Name"}
                              id={`employeeHeadCount.${index}`}
                              value={values.employeeHeadCount[index]}
                              onChange={(e) => {
                                setFieldValue(
                                  `employeeHeadCount.${index}`,
                                  e.target.value
                                );
                              }}
                              onBlur={() =>
                                setFieldTouched(
                                  `employeeHeadCount.${index}`,
                                  true
                                )
                              }
                              error={
                                errors.employeeHeadCount &&
                                errors.employeeHeadCount[index] &&
                                touched.employeeHeadCount &&
                                touched.employeeHeadCount[index] && (
                                  <div className="text-red-500">
                                    {errors.employeeHeadCount[index]}
                                  </div>
                                )
                              }
                              disabled={disabled}
                            />
                          </div>
                          {!disabled && (
                            <div
                              onClick={() => remove(index)}
                              className={`rounded-full cursor-pointer border-[1px] border-black h-[60px] w-[60px] flex justify-center items-center hover:bg-slate-300 ${
                                index > -1 ? "block" : "hidden"
                              }`}
                            >
                              <DeleteSvg />
                            </div>
                          )}
                        </div>
                      ))}
                      {!disabled && (
                        <Button
                          type={"button"}
                          onClick={() => push("")}
                          label={
                            <div className="flex gap-2">
                              <FiPlusCircle color={"#A3A3AB"} />
                              <div className="text-white">
                                Add More Employee Head Count
                              </div>
                            </div>
                          }
                          className={"w-[60%] mt-2 btn-secondary-solid"}
                          style="solid"
                        />
                      )}
                    </div>
                  )}
                </FieldArray>
              }
            />
          </div>
          <div className="py-5">
            <Accordion
              title={"Company Industry"}
              icons={[]}
              children={
                <FieldArray name="companyIndustry">
                  {({ push, remove }) => (
                    <div className="w-full flex flex-col gap-2 pt-3">
                      {typeof errors.companyIndustry == "string" && (
                        <div className="text-red-500">
                          {errors.companyIndustry}
                        </div>
                      )}
                      {values.companyIndustry.map((item, index) => (
                        <div key={index} className="flex gap-2 w-full">
                          <div className="w-[60%] flex gap-3">
                            <InputText
                              label={null}
                              title={"Company Industry Name"}
                              id={`companyIndustry.${index}`}
                              value={values.companyIndustry[index]}
                              onChange={(e) => {
                                setFieldValue(
                                  `companyIndustry.${index}`,
                                  e.target.value
                                );
                              }}
                              onBlur={() =>
                                setFieldTouched(
                                  `companyIndustry.${index}`,
                                  true
                                )
                              }
                              error={
                                errors.companyIndustry &&
                                errors.companyIndustry[index] &&
                                touched.companyIndustry &&
                                touched.companyIndustry[index] && (
                                  <div className="text-red-500">
                                    {errors.companyIndustry[index]}
                                  </div>
                                )
                              }
                              disabled={disabled}
                            />
                          </div>
                          {!disabled && (
                            <div
                              onClick={() => remove(index)}
                              className={`rounded-full cursor-pointer border-[1px] border-black h-[60px] w-[60px] flex justify-center items-center hover:bg-slate-300 ${
                                index > -1 ? "block" : "hidden"
                              }`}
                            >
                              <DeleteSvg />
                            </div>
                          )}
                        </div>
                      ))}
                      {!disabled && (
                        <Button
                          type={"button"}
                          onClick={() => push("")}
                          label={
                            <div className="flex gap-2">
                              <FiPlusCircle color={"#A3A3AB"} />
                              <div className="text-white">
                                Add More Company Industries
                              </div>
                            </div>
                          }
                          className={"w-[60%] mt-2 btn-secondary-solid"}
                          style="solid"
                        />
                      )}
                    </div>
                  )}
                </FieldArray>
              }
            />
          </div>
        </React.Fragment>
      )}
      <div className="py-5">
        <Accordion
          title={"Hobbies"}
          icons={[]}
          children={
            <FieldArray name="hobbies">
              {({ push, remove }) => (
                <div className="w-full flex flex-col gap-2 pt-3">
                  {typeof errors.hobbies == "string" && (
                    <div className="text-red-500">{errors.hobbies}</div>
                  )}
                  {values.hobbies.map((item, index) => (
                    <div key={index} className="flex gap-2 w-full">
                      <div className="w-[60%] flex gap-3">
                        <InputText
                          label={null}
                          title={"Hobby Name"}
                          id={`hobbies.${index}`}
                          value={values.hobbies[index]}
                          onChange={(e) => {
                            setFieldValue(`hobbies.${index}`, e.target.value);
                          }}
                          onBlur={() =>
                            setFieldTouched(`hobbies.${index}`, true)
                          }
                          error={
                            errors.hobbies &&
                            errors.hobbies[index] &&
                            touched.hobbies &&
                            touched.hobbies[index] && (
                              <div className="text-red-500">
                                {errors.hobbies[index]}
                              </div>
                            )
                          }
                          disabled={disabled}
                        />
                      </div>
                      {!disabled && (
                        <div
                          onClick={() => remove(index)}
                          className={`rounded-full cursor-pointer border-[1px] border-black h-[60px] w-[60px] flex justify-center items-center hover:bg-slate-300 ${
                            index > -1 ? "block" : "hidden"
                          }`}
                        >
                          <DeleteSvg />
                        </div>
                      )}
                    </div>
                  ))}
                  {!disabled && (
                    <Button
                      type={"button"}
                      onClick={() => push("")}
                      label={
                        <div className="flex gap-2">
                          <FiPlusCircle color={"#A3A3AB"} />
                          <div className="text-white">Add More Hobby</div>
                        </div>
                      }
                      className={"w-[60%] mt-2 btn-secondary-solid"}
                      style="solid"
                    />
                  )}
                </div>
              )}
            </FieldArray>
          }
        />
      </div>
      <div className="py-5">
        <Accordion
          title={"Skills"}
          icons={[]}
          children={
            <FieldArray name="skills">
              {({ push, remove }) => (
                <div className="w-full flex flex-col gap-2 pt-3">
                  {typeof errors.skills == "string" && (
                    <div className="text-red-500">{errors.skills}</div>
                  )}
                  {values.skills.map((item, index) => (
                    <div key={index} className="flex gap-2 w-full">
                      <div className="w-[60%] flex gap-3">
                        <InputText
                          label={null}
                          title={"Skill Name"}
                          id={`skills.${index}`}
                          value={values.skills[index]}
                          onChange={(e) => {
                            setFieldValue(`skills.${index}`, e.target.value);
                          }}
                          onBlur={() =>
                            setFieldTouched(`skills.${index}`, true)
                          }
                          error={
                            errors.skills &&
                            errors.skills[index] &&
                            touched.skills &&
                            touched.skills[index] && (
                              <div className="text-red-500">
                                {errors.skills[index]}
                              </div>
                            )
                          }
                          disabled={disabled}
                        />
                      </div>
                      {!disabled && (
                        <div
                          onClick={() => remove(index)}
                          className={`rounded-full cursor-pointer border-[1px] border-black h-[60px] w-[60px] flex justify-center items-center hover:bg-slate-300 ${
                            index > -1 ? "block" : "hidden"
                          }`}
                        >
                          <DeleteSvg />
                        </div>
                      )}
                    </div>
                  ))}
                  {!disabled && (
                    <Button
                      type={"button"}
                      onClick={() => push("")}
                      label={
                        <div className="flex gap-2">
                          <FiPlusCircle color={"#A3A3AB"} />
                          <div className="text-white">Add More Skill</div>
                        </div>
                      }
                      className={"w-[60%] mt-2 btn-secondary-solid"}
                      style="solid"
                    />
                  )}
                </div>
              )}
            </FieldArray>
          }
        />
      </div>
      <div className="py-5">
        <Accordion
          title={"Type Of Allowance"}
          icons={[]}
          children={
            <FieldArray name="typeOfAllowances">
              {({ push, remove }) => (
                <div className="w-full flex flex-col gap-2 pt-3">
                  {typeof errors.typeOfAllowances == "string" && (
                    <div className="text-red-500">
                      {errors.typeOfAllowances}
                    </div>
                  )}
                  {values.typeOfAllowances.map((item, index) => (
                    <div key={index} className="flex gap-2 w-full">
                      <div className="w-[60%] flex gap-3">
                        <InputText
                          label={null}
                          title={"Type Of Allowance Name"}
                          id={`typeOfAllowances.${index}`}
                          value={values.typeOfAllowances[index]}
                          onChange={(e) => {
                            setFieldValue(
                              `typeOfAllowances.${index}`,
                              e.target.value
                            );
                          }}
                          onBlur={() =>
                            setFieldTouched(`typeOfAllowances.${index}`, true)
                          }
                          error={
                            errors.typeOfAllowances &&
                            errors.typeOfAllowances[index] &&
                            touched.typeOfAllowances &&
                            touched.typeOfAllowances[index] && (
                              <div className="text-red-500">
                                {errors.typeOfAllowances[index]}
                              </div>
                            )
                          }
                          disabled={disabled}
                        />
                      </div>
                      {!disabled && (
                        <div
                          onClick={() => remove(index)}
                          className={`rounded-full cursor-pointer border-[1px] border-black h-[60px] w-[60px] flex justify-center items-center hover:bg-slate-300 ${
                            index > -1 ? "block" : "hidden"
                          }`}
                        >
                          <DeleteSvg />
                        </div>
                      )}
                    </div>
                  ))}
                  {!disabled && (
                    <Button
                      type={"button"}
                      onClick={() => push("")}
                      label={
                        <div className="flex gap-2">
                          <FiPlusCircle color={"#A3A3AB"} />
                          <div className="text-white">
                            Add More Type Of Allowance
                          </div>
                        </div>
                      }
                      className={"w-[60%] mt-2 btn-secondary-solid"}
                      style="solid"
                    />
                  )}
                </div>
              )}
            </FieldArray>
          }
        />
      </div>
    </form>
  );
}

export default FormSetting;
