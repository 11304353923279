import React, { useState } from "react";
import { Accordion, ModalConfirmation } from "@bluesilodev/timhutcomponents";
import { FiPlusSvg, FiEdit, FiSave2Svg, DeleteSvg } from "components/icons";
import AddOrEditForm from "./AddOrEditForm";
import { educationSchema } from "./educationSchema";
import TimhutPng from "assets/timhut.png";

function Education({ formikVal, setFormikVal, disabled }) {
  const [forms, setForms] = useState([
    {
      id: Date.now(),
      school: "",
      grade: "",
      degree: "",
      startDate: "",
      fieldOfStudy: "",
      endDate: "",
    },
  ]);

  const [editingIndexes, setEditingIndexes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);

  const handleDeleted = () => {
    const updatedFormikVal = [...formikVal];
    updatedFormikVal.splice(deleteIndex, 1);
    setFormikVal(updatedFormikVal);
    setEditingIndexes(editingIndexes.filter((index) => index !== deleteIndex));
    setShowModal(false);
    setDeleteIndex(null);
  };

  const addForm = () => {
    setForms([
      ...forms,
      {
        id: Date.now(),
        school: "",
        grade: "",
        degree: "",
        startDate: "",
        fieldOfStudy: "",
        endDate: "",
      },
    ]);
  };

  const removeAddForm = (id) => {
    setForms(forms.filter((form) => form.id !== id));
  };

  // GET VALUE FROM COMPONEN
  const getValue = (val, index) => {
    const updatedFormikVal = [...formikVal];
    if (index >= 0) {
      updatedFormikVal[index] = val;
    } else {
      updatedFormikVal.push(val);
    }
    setFormikVal(updatedFormikVal);
  };

  const deleteFormVal = (idx) => {
    setDeleteIndex(idx);
    setShowModal(true);
  };

  const editFormVal = (idx) => {
    setEditingIndexes([...editingIndexes, idx]);
  };

  const removeEditForm = (idx) => {
    setEditingIndexes(editingIndexes.filter((index) => index !== idx));
  };

  function formatDateWithNumber(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }

  return (
    <div>
      <Accordion
        title={"Education Institute"}
        icons={
          disabled
            ? []
            : [
                {
                  icon: <FiPlusSvg onClick={addForm} />,
                },
                {
                  icon: <FiSave2Svg />,
                },
              ]
        }
      >
        <div className="w-full flex flex-col pt-2 pb-3">
          <div>
            {Array.isArray(formikVal) &&
              formikVal.map((item, index) => {
                return (
                  <div key={index} className="flex flex-col gap-1 mt-5">
                    <div className="w-full h-[100px] p-2 border-[1px] border-black rounded-md flex justify-between items-center ">
                      <div className="flex gap-3">
                        <img
                          src={TimhutPng}
                          alt="educationImg"
                          className="w-[67px] h-[80px]"
                        />

                        <div className="flex flex-col justify-around">
                          <h1 className="font-semibold text-lg">
                            {item.school}
                          </h1>
                          <div>{item.degree}</div>
                          <p>
                            {formatDateWithNumber(item.startDate) +
                              " - " +
                              formatDateWithNumber(item.endDate)}
                          </p>
                        </div>
                      </div>

                      <div className="flex gap-5 pr-3">
                        <FiEdit
                          className={"w-[20px] h-[20px]"}
                          onClick={() => editFormVal(index)}
                        />

                        <DeleteSvg
                          className={"w-[20px] h-[20px]"}
                          color={"black"}
                          onClick={() => deleteFormVal(index)}
                        />
                      </div>
                    </div>

                    {editingIndexes.includes(index) && (
                      <div>
                        <AddOrEditForm
                          key={`edit-${index}`}
                          formId={index}
                          initialValues={item}
                          removeForm={removeEditForm}
                          formikVal={formikVal}
                          getValue={getValue}
                          title={"Edit Education"}
                          index={index}
                          isEdit={true}
                          validationSchema={educationSchema}
                          disabled={disabled}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
          </div>

          {forms.map((form, idx) => {
            return (
              <AddOrEditForm
                key={form.id}
                formId={form.id}
                initialValues={{
                  school: form.school,
                  degree: form.degree,
                  grade: form.grade,
                  startDate: form.startDate,
                  fieldOfStudy: form.fieldOfStudy,
                  endDate: form.endDate,
                }}
                validationSchema={educationSchema}
                removeForm={removeAddForm}
                getValue={getValue}
                formikVal={formikVal}
                title={"Add New Education"}
                index={-1}
                isEdit={false}
                id={form.id}
                disabled={disabled ? disabled : false}
              />
            );
          })}
        </div>
      </Accordion>

      {showModal && (
        <ModalConfirmation
          headMessage="Are you sure want to delete it?"
          onClose={() => setShowModal(false)}
          onConfirm={handleDeleted}
        />
      )}
    </div>
  );
}

export default Education;
