import React, { useState, useCallback, useRef, useEffect } from "react";
import { FiZoomIn, FiZoomOut } from "components/icons";

const ZoomDragContainer = ({ children, minZoom = 0.5, maxZoom = 3 }) => {
  const [scale, setScale] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const dragStartRef = useRef({ x: 0, y: 0 });

  // Calculate bounds based on container and content size
  const getBounds = useCallback(() => {
    if (!containerRef.current || !contentRef.current)
      return { minX: 0, maxX: 0, minY: 0, maxY: 0 };

    const container = containerRef.current.getBoundingClientRect();
    const content = contentRef.current.getBoundingClientRect();

    // Get real dimensions of content (accounting for current scale)
    const realWidth = content.width / scale;
    const realHeight = content.height / scale;

    // Calculate dimensions at current zoom level
    const scaledWidth = realWidth * scale;
    const scaledHeight = realHeight * scale;

    // Calculate bound differences
    const diffX = Math.max(0, scaledWidth - container.width);
    const diffY = Math.max(0, scaledHeight - container.height);

    return {
      minX: -diffX,
      maxX: 0,
      minY: -diffY,
      maxY: 0,
    };
  }, [scale]);

  // Set initial position
  useEffect(() => {
    const initializePosition = () => {
      if (!containerRef.current || !contentRef.current) return;

      const container = containerRef.current.getBoundingClientRect();
      const content = contentRef.current.getBoundingClientRect();

      // Set initial position to top center
      const initialX = (container.width - content.width) / 2;
      setPosition({ x: initialX, y: 0 });
    };

    initializePosition();
    // Add resize observer to handle window/container size changes
    const resizeObserver = new ResizeObserver(initializePosition);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, []);

  // Clamp position within bounds
  const clampPosition = useCallback(
    (pos) => {
      const bounds = getBounds();
      return {
        x: Math.min(bounds.maxX, Math.max(bounds.minX, pos.x)),
        y: Math.min(bounds.maxY, Math.max(bounds.minY, pos.y)),
      };
    },
    [getBounds]
  );

  // Handle mouse wheel for zooming
  const handleWheel = useCallback(
    (e) => {
      e.stopPropagation();

      if (e.ctrlKey || e.metaKey) {
        e.preventDefault();
        const delta = e.deltaY * -0.01;
        const newScale = Math.min(maxZoom, Math.max(minZoom, scale + delta));

        // Calculate cursor position relative to container
        const rect = containerRef.current.getBoundingClientRect();
        const x = (e.clientX - rect.left - position.x) / scale;
        const y = (e.clientY - rect.top - position.y) / scale;

        // Calculate new position that keeps the point under cursor in the same place
        const newPos = {
          x: position.x - x * (newScale - scale),
          y: position.y - y * (newScale - scale),
        };

        setScale(newScale);
        setPosition(clampPosition(newPos));
      }
    },
    [scale, position, minZoom, maxZoom, clampPosition]
  );

  // Handle mouse events for dragging
  const handleMouseDown = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      setIsDragging(true);
      dragStartRef.current = {
        x: e.clientX - position.x,
        y: e.clientY - position.y,
      };
    },
    [position]
  );

  const handleMouseMove = useCallback(
    (e) => {
      e.stopPropagation();
      if (!isDragging) return;

      const newPosition = {
        x: e.clientX - dragStartRef.current.x,
        y: e.clientY - dragStartRef.current.y,
      };

      setPosition(clampPosition(newPosition));
    },
    [isDragging, clampPosition]
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  // Handle touch events for mobile
  const handleTouchStart = useCallback(
    (e) => {
      e.stopPropagation();
      if (e.touches.length === 1) {
        setIsDragging(true);
        dragStartRef.current = {
          x: e.touches[0].clientX - position.x,
          y: e.touches[0].clientY - position.y,
        };
      }
    },
    [position]
  );

  const handleTouchMove = useCallback(
    (e) => {
      e.stopPropagation();
      if (!isDragging || e.touches.length !== 1) return;

      const newPosition = {
        x: e.touches[0].clientX - dragStartRef.current.x,
        y: e.touches[0].clientY - dragStartRef.current.y,
      };

      setPosition(clampPosition(newPosition));
    },
    [isDragging, clampPosition]
  );

  // Add event listeners
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    container.addEventListener("wheel", handleWheel, { passive: false });
    return () => container.removeEventListener("wheel", handleWheel);
  }, [handleWheel]);

  return (
    <div
      ref={containerRef}
      className="relative overflow-hidden w-full flex-1"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleMouseUp}
      style={{ cursor: isDragging ? "grabbing" : "grab" }}
    >
      <div
        ref={contentRef}
        className="absolute top-0 left-0 origin-top-left"
        style={{
          transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`,
          transition: isDragging ? "none" : "transform 0.1s",
        }}
      >
        {children}
      </div>

      {/* Zoom controls */}
      <div className="absolute right-3 flex gap-3 rounded-lg shadow">
        <button
          onClick={() => {
            const newScale = Math.min(maxZoom, scale + 0.1);
            setScale(newScale);
            setPosition(clampPosition(position));
          }}
          className="border-[1px] border-black p-3 rounded-md cursor-pointer bg-white"
          aria-label="Zoom in"
        >
          <FiZoomIn />
        </button>
        <button
          onClick={() => {
            const newScale = Math.max(minZoom, scale - 0.1);
            setScale(newScale);
            setPosition(clampPosition(position));
          }}
          className="border-[1px] border-black p-3 rounded-md cursor-pointer bg-white"
          aria-label="Zoom out"
        >
          <FiZoomOut />
        </button>
      </div>
    </div>
  );
};

export default ZoomDragContainer;
