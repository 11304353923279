import React from "react";
import { LeaveTypeSettingsForm } from "@bluesilodev/timhutcomponents";
import { useDispatch, useSelector } from "react-redux";
import { updateLeaveData, updateEditLeaveData } from "store/employeeSlice";
import {
  useUpdateLeaveTypeByUser,
  useUpdateLeaveTypeByAdmin,
} from "service/leaveApi";
import { alertSuccess, alertError } from "utils/alert";

function FormLeave({ data, showModal, setShowModal }) {
  const dispatch = useDispatch();

  const initialVal = {
    name: data.name,
    gainPerMonth: data.gainPerMonth,
    expiredCarryOver: parseInt(data.expiredCarryOver, 10),
    defaultAmount: parseInt(data.defaultAmount, 10),
    maxCarryOver: parseInt(data.maxCarryOver, 10),
    periodOfEmployments: data.periodOfEmployments,
    isFullLeaveAmount: data.isFullLeaveAmount,
  };

  // Hook for user edit leave mutation
  // const { mutate: mutateUpdateLeaveUser, isLoadingUser } =
  //   useUpdateLeaveTypeByUser({
  //     onSuccess: (response) => {
  //       console.log("Leave type user updated successfully:", response);
  //       alertSuccess("Leave type user updated successfully");
  //     },
  //     onError: (error) => {
  //       console.error("Error updating leave type user:", error);
  //       alertError("Error updating leave type user");
  //     },
  //   });

  // Hook for admin edit leave mutation
  const { mutate: mutateUpdateLeave, isLoading } = useUpdateLeaveTypeByAdmin({
    onSuccess: (response) => {
      alertSuccess("Leave type updated successfully");
    },
    onError: (error) => {
      console.error("Error updating leave type:", error);
      alertError("Error updating leave type");
    },
  });

  // Handle form submission
  const handleFormSubmit = (val) => {
    const allVal = {
      ...val,
      organizationID: data.organizationID,
      uId: data.uId,
    };

    // Dispatch actions to update state
    dispatch(updateLeaveData(allVal));
    dispatch(updateEditLeaveData(allVal));

    mutateUpdateLeave(allVal);

    setShowModal(false);
  };

  return (
    <div className="w-full">
      {showModal && (
        <LeaveTypeSettingsForm
          initialValue={initialVal}
          onSubmit={handleFormSubmit} // Use the new function here
          visibleModal={showModal}
          setVisibleModal={setShowModal}
        />
      )}
    </div>
  );
}

export default FormLeave;
