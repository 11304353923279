import React, { useState } from "react";
import {
  ModalConfirmation,
  ModalConfirmationV2,
} from "@bluesilodev/timhutcomponents";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import { useDeleteEmployeeStatus } from "service/employeeStatusAPI";
import { adminRole } from "utils/roleFromLocalStorage";

import FillTableMenu from "components/FillTableMenu";
import { useSelector } from "react-redux";

function TableMenu({ data }) {
  const [show, setShow] = useState(false);
  const [uIdData, setuIdData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { currentRole } = useSelector((state) => state.loginSlice);

  const handleShow = () => {
    setShow(!show);
  };

  const onEditEmployee = (uId) => {
    navigate(`/editEmployeeStatus/${uId}`);
  };

  // DELETE USER
  const { mutate, isPending } = useDeleteEmployeeStatus(uIdData, {
    onSuccess: () => {
      queryClient.invalidateQueries("getLocationById");
      alert("SUCCESS DELETED DATA");
    },
    onError: () => {
      alert("FAILED DELETE DATA!");
    },
  });

  const onDeleteEmployee = (uId) => {
    setuIdData(uId);
    setShowModal(true);
  };

  const confirmModal = () => {
    mutate(uIdData);
    setShowModal(false);
    setShow(false);
  };

  const cancelModal = () => {
    setShowModal(false);
    setShow(false);
  };

  if (isPending) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="relative">
        <FillTableMenu
          currentRole={currentRole}
          handleShow={handleShow}
          onDeleteEmployee={() => onDeleteEmployee(data?.uId)}
          onEditEmployee={() => onEditEmployee(data?.uId)}
          showSetting={show}
          setShow={setShow}
        />

        {showModal && (
          // <ModalConfirmation
          //   headMessage="Are you sure want to delete it?"
          //   onClose={cancelModal}
          //   onConfirm={confirmModal}
          // />
          <ModalConfirmationV2
            className={"min-w-[600px]"}
            title={"Delete Employee Status"}
            headMessage={""}
            message={
              <div className="text-left w-full text-lg font-light">
                <p>Do you want to delete this employee status type?</p>
                <p className="font-semibold">{data.employeeType}</p>
              </div>
            }
            // onClose={() => setIsShow(false)}
            onClose={cancelModal}
            // onConfirm={() => {
            //   requestPayroll({ id: original?._id });
            //   dispatch(PayrollApi.util.invalidateTags(["payroll"]));
            //   setIsShow(false);
            // }}
            onConfirm={confirmModal}
          />
        )}
      </div>
    </>
  );
}

export default TableMenu;
