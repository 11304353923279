import * as yup from "yup";

export const editLocationSchema = yup.object().shape({
  locationName: yup.string().matches(/^[A-Za-z0-9 _-]+$/, 'Only letters, numbers, spaces, underscores, and dashes are allowed').required("Location Name is Required"),
  country: yup.string().required("Country is Required"),
  postalCode: yup.number().required("Postal Code is Required"),
  countryPhoneNumber: yup.number().required('Country Number is required'),
  phoneNumber: yup.number().required("Phone Number is Required"),
  address: yup.string().required("Company Address is Required"),
  departments: yup.array().of(
    yup.object().shape({
      department: yup.string().matches(/^[A-Za-z0-9 _-]+$/, 'Only letters, numbers, spaces, underscores, and dashes are allowed').required("Department name is required"),
      jobPosition: yup
        .array()
        .of(yup.string().matches(/^[A-Za-z0-9 _-]+$/, 'Only letters, numbers, spaces, underscores, and dashes are allowed'))
        .min(1, "At least one job position is required")
        .required("At least one job position is required"),
    })
  ),
});
