import React, { useEffect } from "react";
import { Accordion, DataTable } from "@bluesilodev/timhutcomponents";
import { useFormikContext } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ChevronSvg } from "components/icons";
import { useGetLeaveById, useGetLeaveGlobal } from "service/leaveApi";

import EmployeeMentDetailShare from "pages/shared/formUser/EmployeementDetailShare";
import ActionLeave from "pages/shared/actionLeave";
import { setEditLeaveData } from "store/employeeSlice";

function SuperAdminEmployeementDetails() {
  const { values, handleBlur, handleChange, errors, touched, setFieldValue } =
    useFormikContext();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { leaveEditData } = useSelector((state) => state.employeeSlice);

  // COLUMN
  const dataColumn = [
    {
      id: "leaveType",
      accessorFn: (row) => row.name,
      header: () => <span>Leave Type</span>,
      enableSorting: true,
    },
    {
      id: "amount",
      accessorFn: (row) => row.defaultAmount,
      header: () => <span>Default Amount</span>,
      enableSorting: true,
    },
    {
      id: "gainPermont",
      accessorFn: (row) => row.gainPerMonth,
      header: () => <span>Gain Per Month</span>,
      enableSorting: true,
    },
    {
      id: "expired",
      accessorFn: (row) => row.expiredCarryOver,
      header: () => <span>Expired Caryy Over</span>,
      enableSorting: true,
    },
    {
      id: "maxCarry",
      accessorFn: (row) => row?.maxCarryOver,
      header: () => <span>Max Carry Over</span>,
      enableSorting: true,
    },

    {
      id: "action",
      disableSorting: true,
      header: () => "",
      accessorFn: (row) => {
        return row && row;
      },
      cell: (param) => {
        if (param) {
          const data = param.getValue();

          return (
            <>
              <ActionLeave data={data} />
            </>
          );
        }
      },
    },
  ];

  const { uId } = useParams();

  const { data: dataIndividual, isSuccess: succesIndividual } = useGetLeaveById(
    uId,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getLeaveDataID");
      },
      onError: (err) => {
        console.log("ERR : ", err);
      },
    }
  );

  const { data: globalData, isSuccess: successGlobal } = useGetLeaveGlobal({
    onSuccess: () => {
      queryClient.invalidateQueries("globalLeave");
    },
    onError: (err) => {
      console.log("ERR: ", err);
    },
  });

  useEffect(() => {
    if (succesIndividual && successGlobal && dataIndividual && globalData) {
      if (
        dataIndividual.data.leaveTypeSettings &&
        dataIndividual.data.leaveTypeSettings.length > 0
      ) {
        dispatch(setEditLeaveData(dataIndividual.data.leaveTypeSettings));
      } else {
        dispatch(setEditLeaveData(globalData.data));
      }
    }
  }, [succesIndividual, successGlobal, dataIndividual, globalData]);

  return (
    <div className="w-full py-5">
      <EmployeeMentDetailShare
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        touched={touched}
        values={values}
        setFieldValue={setFieldValue}
      />

      <div className="py-5">
        <Accordion
          title={"Leave Settings"}
          icons={[{ icon: <ChevronSvg /> }]}
          children={
            <div className="py-5">
              <div className="pt-5">
                <DataTable
                  columns={dataColumn}
                  data={leaveEditData ? leaveEditData : []}
                  maxHeight={450}
                />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
}

export default SuperAdminEmployeementDetails;
