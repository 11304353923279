import React, { useEffect } from "react";
import { Accordion, DataTable } from "@bluesilodev/timhutcomponents";
import { useFormikContext } from "formik";
import { useGetLeaveGlobal } from "service/leaveApi";
import { useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import EmployeeMentDetailShare from "pages/shared/formUser/EmployeementDetailShare";
import ActionLeave from "pages/shared/actionLeave";
import { setLeaveData } from "store/employeeSlice";

function EmployeeMentDetails() {
  const dispatch = useDispatch();

  const { values, handleBlur, handleChange, errors, touched, setFieldValue } =
    useFormikContext();
  const queryClient = useQueryClient();
  const { leaveData } = useSelector((state) => state.employeeSlice);

  // GET DATA GLOBAL LEAVE ADMIN
  const {
    data: globalData,
    isPending: pendingGlobal,
    isSuccess,
  } = useGetLeaveGlobal({
    onSuccess: () => {
      queryClient.invalidateQueries("globalLeave");
    },
    onError: (err) => {
      console.log("ERR: ", err);
    },
  });

  useEffect(() => {
    if (isSuccess && globalData) {
      dispatch(setLeaveData(globalData.data));
    }
  }, [globalData, isSuccess]);

  if (pendingGlobal) {
    return <div>Loading...</div>;
  }

  // COLUMN
  const dataColumn = [
    {
      id: "leaveType",
      accessorFn: (row) => {
        return row.name;
      },
      header: () => <span>Leave Type</span>,
      enableSorting: true,
    },
    {
      id: "amount",
      accessorFn: (row) => row.defaultAmount,
      header: () => <span>Default Amount</span>,
      enableSorting: true,
    },
    {
      id: "gainPermont",
      accessorFn: (row) => row.gainPerMonth,
      header: () => <span>Gain Per Month</span>,
      enableSorting: true,
    },
    {
      id: "expired",
      accessorFn: (row) => row.expiredCarryOver,
      header: () => <span>Expired Caryy Over</span>,
      enableSorting: true,
    },
    {
      id: "maxCarry",
      accessorFn: (row) => row?.maxCarryOver,
      header: () => <span>Max Carry Over</span>,
      enableSorting: true,
    },
  ];

  return (
    <div className="w-full ">
      <EmployeeMentDetailShare
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        touched={touched}
        values={values}
        setFieldValue={setFieldValue}
        disabled={true}
        typeFor={"details"}
      />

      <div className="w-full">
        <Accordion
          title={"Leave Settings"}
          icons={[]}
          children={
            <div className="py-5">
              <div className="pt-5">
                <DataTable
                  columns={dataColumn}
                  data={leaveData}
                  pagination={true}
                  title="Employee Data"
                  maxHeight={450}
                />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
}

export default EmployeeMentDetails;
