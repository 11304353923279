import React from "react";
import {
  Accordion,
  InputText,
  InputSelect,
  CheckBox,
  Button,
  InputDate,
  UploadImg1,
} from "@bluesilodev/timhutcomponents";
import { useQueryClient } from "@tanstack/react-query";
import { Formik } from "formik";

import { useGetAllLocation } from "service/locationAPI";

import { FiSaveSvg } from "components/icons";

function FormEventShared({
  initialValues,
  handleSubmitForm,
  validationSchema,
  handleDuration,
}) {
  const queryClient = useQueryClient();

  const { data, isPending } = useGetAllLocation({
    onSuccess: () => {
      queryClient.invalidateQueries("getLocation");
    },
    onError: (err) => {
      console.log(err);
    },
  });

  if (isPending) {
    return <h1>Loading...</h1>;
  }

  const dataLocation = data?.data.map((item, _) => {
    return {
      label: item.locationName,
      value: item.uId,
    };
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmitForm}
      className="w-full overflow-y-auto mt-8 py-3 px-1"
    >
      {({
        handleBlur,
        errors,
        touched,
        values,
        setFieldValue,
        handleChange,
        handleSubmit,
      }) => {
        // CALCULATION DATE
        const [startDay, startMonth, startYear] = values.startDate.split("/");
        const [endDay, endMonth, endYear] = values.endDate.split("/");

        const start = new Date(startYear, startMonth - 1, startDay);
        const end = new Date(endYear, endMonth - 1, endDay);

        const timeDiff = end.getTime() - start.getTime();

        if (timeDiff) {
          values.duration = timeDiff / (1000 * 3600 * 24);
        }

        // Function for change file
        const changeFile = (e) => {
          const file = e.currentTarget.files[0];

          if (file) {
            setFieldValue("posterAttachment", file);
          }
        };

        return (
          <>
            <div className="w-full flex justify-end pt-5">
              <Button
                style="solid"
                className={"w-[200px]"}
                onClick={handleSubmit}
                type={"submit"}
                label={
                  <div className="flex gap-2">
                    <FiSaveSvg />
                    <div>Save</div>
                  </div>
                }
              />
            </div>

            <div className="max-h-[500px] overflow-y-auto" >
              <Accordion
                title={"Event Details"}
                icons={[]}
                children={
                  <div className="py-5 w-[70%] flex flex-col gap-3 ">
                    <div className="flex w-full gap-3">
                      <InputText
                        id="nameEvent"
                        title={"Name of Events"}
                        value={values.nameEvent}
                        required={true}
                        label={null}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          errors.nameEvent &&
                          touched.nameEvent &&
                          errors.nameEvent
                        }
                      />
                      <InputSelect
                        title={"Locations"}
                        options={dataLocation}
                        value={values.location}
                        required={true}
                        id="location"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          errors.location && touched.location && errors.location
                        }
                      />
                    </div>

                    <div className="flex w-full gap-3">
                      <InputDate
                        name={"startDate"}
                        label={"Start Date"}
                        value={values.startDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        errors={
                          errors.startDate &&
                          touched.startDate &&
                          errors.startDate
                        }
                        required={true}
                      />

                      <InputDate
                        name={"endDate"}
                        label={"End Date"}
                        value={values.endDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        errors={
                          errors.endDate && touched.endDate && errors.endDate
                        }
                        required={true}
                      />
                    </div>

                    <div className="flex w-full gap-3 pt-3">
                      <input
                        id="duration"
                        disabled={true}
                        onChange={handleDuration}
                        value={values.duration + " Day(s)"}
                        className="bg-gray-200 w-full py-1 pl-4 rounded-md h-[60px] flex items-center  "
                      />

                      <div className="flex w-full items-center gap-3">
                        <div>
                          <CheckBox
                            name={"isCompanyHoliday"}
                            onChange={(v) =>
                              setFieldValue("isCompanyHoliday", v)
                            }
                            value={values.isCompanyHoliday}
                          />
                        </div>
                        <p>Is This Company Holiday ? </p>
                      </div>
                    </div>

                    <div className="my-4">
                      <UploadImg1
                        onChange={changeFile}
                        textSizeLimit={
                          "For Best Resolution is 1366 x 1366px, Max upload image size is 2MB, Supported files: .jpg, .png "
                        }
                        title={"Attachment"}
                        onBlur={handleBlur}
                        id="posterAttachment"
                        accept="image/jpeg, image/jpg, image/png"
                        message={
                          errors.posterAttachment &&
                          touched.posterAttachment ? (
                            <div className="text-red-500">
                              {errors.posterAttachment}
                            </div>
                          ) : (
                            values?.posterAttachment[0]?.name ||
                            values?.posterAttachment?.name
                          )
                        }
                      />
                    </div>
                    <div>
                      <textarea
                        id="note"
                        onChange={handleChange}
                        value={values.note}
                        onBlur={handleBlur}
                        placeholder="Notes"
                        className="w-full border-[1px] border-black resize-none h-[100px] rounded-md outline-none p-3"
                      ></textarea>
                    </div>
                  </div>
                }
              />
            </div>
          </>
        );
      }}
    </Formik>
  );
}

export default FormEventShared;
