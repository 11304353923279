import axios from "axios";
import { setLogoutReducer } from "store/userLoginSlice";
import { store } from "store";

export const getToken = () => localStorage.getItem("accessToken");

const getBearerToken = () => {
  const token = getToken();
  return token ? `Bearer ${token}` : null;
};

const customAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const LeaveAxios = axios.create({
  baseURL: process.env.REACT_APP_LEAVE_API_URL,
});

customAxios.interceptors.request.use(
  (request) => {
    const token = getBearerToken();
    if (token) {
      request.headers.Authorization = token;
    }
    return request;
  },
  (err) => {
    return Promise.reject(err);
  }
);

customAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err.response && [401, 403].includes(err.response.status)) {
      localStorage.clear();
      store.dispatch(setLogoutReducer());
      setTimeout(() => window.location.assign("/dashboard/login"), 100);
    }
    return Promise.reject(err);
  }
);

LeaveAxios.interceptors.request.use(
  (request) => {
    const token = getBearerToken();
    if (token) {
      request.headers.Authorization = token;
    }
    return request;
  },
  (err) => {
    return Promise.reject(err);
  }
);

LeaveAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err.response && [401, 403].includes(err.response.status)) {
      localStorage.clear();
      store.dispatch(setLogoutReducer());
      setTimeout(() => window.location.assign("/dashboard/login"), 100);
    }
    return Promise.reject(err);
  }
);

export { customAxios, LeaveAxios };
