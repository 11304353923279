import React, { useState } from "react";
import { useDeleteUser } from "service/userAPI";
import { useNavigate } from "react-router-dom";
import { ModalConfirmation } from "@bluesilodev/timhutcomponents";

import { alertError, alertSuccess } from "utils/alert";
import { adminAndSuperVisorRole } from "utils/roleFromLocalStorage";

import { FiLeft, Fi3User, FiMail, FiSmartphone } from "components/icons";

function CardGrid({ item }) {
  const [showSetting, setShowSetting] = useState(false);
  const [uIdData, setuIdData] = useState(null);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const navigate = useNavigate();

  const handleShowSetting = () => {
    setShowSetting(!showSetting);
  };

  // DELETE USER
  const { mutate, isPending } = useDeleteUser(uIdData, {
    onSuccess: () => {
      // queryClient.invalidateQueries("getUser");
      alertSuccess("SUCCESS DELETED DATA");
    },
    onError: () => {
      alertError("FAILED DELETE DATA!");
    },
  });

  const onEditEmployee = (uId) => {
    navigate(`/editEmployee/${uId}`);
  };

  const onDeleteEmployee = (uId) => {
    setuIdData(uId);
    setShowModalDelete(true);
  };

  const confirmModal = () => {
    mutate(uIdData);
    setShowModalDelete(false);
    setShowSetting(false);
  };

  const cancelModal = () => {
    setShowModalDelete(false);
    setShowSetting(false);
  };

  if (isPending) {
    return <div>Loading...</div>;
  }

  const color = item.locations && item?.locations?.color;

  return (
    <div className="w-[290px] h-[310px] shadow-md rounded-[16px]  ">
      <div
        style={{ backgroundColor: color }}
        className=" h-[100px] rounded-t-[16px] p-3 text-sm"
      >
        <div className="w-full flex justify-between ">
          <div
            disabled
            className="border-none outline-none text-white appearance-none opacity-100"
          >
            <h1 className="">
              {item.locations && item?.locations?.locationName}
            </h1>
          </div>
          <div className="relative">
            <FiLeft
              onClick={handleShowSetting}
              color={"white"}
              className={"w-[15px] h-[15px] cursor-pointer "}
            />

            {showSetting && (
              <div className="bg-white w-[200px] absolute top-[19px] rounded-md p-3 shadow-md z-50">
                <div className="flex flex-col gap-3">
                  {/* <h1 className="cursor-pointer hover:text-secondary duration-300 transition-shadow">
                    View Details
                  </h1>
                  <h1 className="cursor-pointer hover:text-secondary duration-300 transition-shadow">
                    Resign
                  </h1> */}
                  {adminAndSuperVisorRole && (
                    <>
                      <div
                        onClick={() => onEditEmployee(item?.uId)}
                        className="cursor-pointer hover:text-secondary duration-300 transition-shadow"
                      >
                        Edit
                      </div>
                      <div
                        onClick={() => onDeleteEmployee(item?.uId)}
                        className="cursor-pointer hover:text-secondary duration-300 transition-shadow"
                      >
                        Delete
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="w-full flex justify-center items-center pt-8">
          <div className="border-white border-[5px] w-[75px] h-[75px] rounded-full flex justify-center items-center">
            <div
              style={{ backgroundImage: `url(${item?.photo[0]?.link})` }}
              className={`bg-white w-[70px] h-[70px] rounded-full  bg-cover bg-center`}
            ></div>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center w-full pt-2 gap-2">
          <h1 className="font-bold">{item?.firstName}</h1>
          <div className="bg-gray-300 px-3 py-1 rounded-lg">
            {item?.role[0]}
          </div>
        </div>

        <div className="pt-3 flex flex-col gap-2">
          <div className="flex gap-1">
            <Fi3User />
            <span className="w-full truncate text-ellipsis">
              {item?.userInformation?.employeementDetail?.departments ||
                (item.locations &&
                  item?.locations[0]?.departments[0]?.department)}
            </span>
          </div>
          <div className="flex gap-1">
            <FiMail />
            <p className="w-full truncate text-ellipsis">{item?.email}</p>
          </div>
          <div className="flex gap-1">
            <FiSmartphone />
            <p className="w-full truncate text-ellipsis">{item?.phoneNumber}</p>
          </div>
        </div>
      </div>
      {showModalDelete && adminAndSuperVisorRole && (
        <ModalConfirmation
          headMessage="Are you sure want to delete it?"
          onClose={cancelModal}
          onConfirm={confirmModal}
        />
      )}
    </div>
  );
}

export default CardGrid;
