import React from "react";
import { LeaveTypeSettingsForm } from "@bluesilodev/timhutcomponents";
import { useAlertApi } from "service/alertAPI";
import {
  useAddLeaveTypeByUser,
  useAddLeaveTypeByAdmin,
} from "service/leaveApi";
import { alertSuccess, alertError } from "utils/alert";

export const LeaveTypeAddModalForm = ({
  addLeaveTypeModal,
  addLeaveTypeOpen,
  canEditLeaveSetting,
}) => {
  //   const [addLeaveType, addLeaveTypeResults] = useAddLeaveType();

  //   const {
  //     mutate: addLeaveTypeUser,
  //     isLoadingUser,
  //     isErrorUser,
  //     dataUser,
  //   } = useAddLeaveTypeByUser({
  //     onSuccess: (data) => {
  //       console.log("Leave type user added successfully:", data);
  //       alertSuccess("Leave type user added successfully");
  //       alertSuccessClose();
  //     },
  //     onError: (error) => {
  //       console.error("Failed to add leave type:", error);
  //       alertError("Failed to add leave type");
  //     },
  //   });

  const {
    mutate: addLeaveType,
    isLoading,
    isError,
    data,
  } = useAddLeaveTypeByAdmin({
    onSuccess: (data) => {
      alertSuccess("Leave type added successfully");
      alertSuccessClose();
    },
    onError: (error) => {
      console.error("Failed to add leave type:", error);
      alertError("Failed to add leave type");
    },
  });

  const alertSuccessClose = () => {
    addLeaveTypeOpen(false);
  };

  //   const clickSaveAddLeaveType = (values) => {
  //     addLeaveType({
  //       name: values.name,
  //       defaultAmount: values.defaultAmount,
  //       gainPerMonth: values.gainPerMonth,
  //       expiredCarryOver: values.expiredCarryOver,
  //       maxCarryOver: values.maxCarryOver,
  //       isFullLeaveAmount: values.isFullLeaveAmount,
  //       periodOfEmployments: values.periodOfEmployments.map((val) => ({
  //         from: val.from,
  //         to: val.to,
  //         equal: val.equal,
  //       })),
  //     });
  //   };
  const handleSave = (values) => {
    const payload = {
      name: values.name,
      defaultAmount: values.defaultAmount,
      gainPerMonth: values.gainPerMonth,
      expiredCarryOver: values.expiredCarryOver,
      maxCarryOver: values.maxCarryOver,
      isFullLeaveAmount: values.isFullLeaveAmount,
      periodOfEmployments: values.periodOfEmployments.map((val) => ({
        from: val.from,
        to: val.to,
        equal: val.equal,
      })),
    };

    addLeaveType(payload);
  };

  return (
    <React.Fragment>
      {addLeaveTypeModal && (
        <LeaveTypeSettingsForm
          visibleModal={addLeaveTypeModal}
          setVisibleModal={addLeaveTypeOpen}
          initialValue={{ expiredCarryOver: 0 }}
          onSubmit={handleSave}
          type={"add"}
        />
      )}
    </React.Fragment>
  );
};

// export const LeaveTypeEditModalForm = ({
//   leaveTypeDetailsModal,
//   leaveTypeDetailsOpen,
//   leaveTypeDetailsEdit,
// }) => {
//   const [updateLeaveType, updateLeaveTypeResults] =
//     useUpdateLeaveTypeMutation();

//   const alertSuccessClose = () => {
//     leaveTypeDetailsOpen(false);
//   };

//   useAlertApi(updateLeaveTypeResults, alertSuccessClose);

//   const clickSaveEditLeaveType = (values) => {
//     updateLeaveType({
//       leaveTypeId: values.leaveTypeId,
//       name: values.name,
//       defaultAmount: values.defaultAmount,
//       gainPerMonth: values.gainPerMonth,
//       expiredCarryOver: values.expiredCarryOver,
//       maxCarryOver: values.maxCarryOver,
//       isFullLeaveAmount: values.isFullLeaveAmount,
//       periodOfEmployments: values.periodOfEmployments.map((val) => ({
//         from: val.from,
//         to: val.to,
//         equal: val.equal,
//       })),
//     });
//   };

//   return (
//     <React.Fragment>
//       {leaveTypeDetailsModal && (
//         <LeaveTypeSettingsForm
//           visibleModal={leaveTypeDetailsModal}
//           setVisibleModal={leaveTypeDetailsOpen}
//           initialValue={leaveTypeDetailsEdit}
//           onSubmit={clickSaveEditLeaveType}
//           type={"edit"}
//         />
//       )}
//     </React.Fragment>
//   );
// };
