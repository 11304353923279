import { createSlice } from "@reduxjs/toolkit";

// SLICE REDUCER
const userInformSlice = createSlice({
  name: "userInformSlice",
  initialState: {
    userInform: "",
    userInformationID: "",
    userNameDetail: "",
  },
  reducers: {
    setUserData(state, action) {
      state.userInform = action.payload;
    },

    setUserInformationID(state, action) {
      state.userInformationID = action.payload;
    },

    setUserName(state, action) {
      state.userNameDetail = action.payload;
    },
  },
});

export const { setUserData, setUserInformationID, setUserName } =
  userInformSlice.actions;
export default userInformSlice.reducer;
