import React, { useState } from "react";
import { Formik } from "formik";
import { Button, BreadcrumbSteps } from "@bluesilodev/timhutcomponents";

import { ChevronRight, ChevronLeft, FiSaveSvg } from "components/icons";
import FormErrorNotificationShared from "./FormErrorNotificationShared";

function FormShared({
  // HANDLE FORMIK
  handleSubmitForm,
  validationSchema,
  //   CHILD REACT CONTEXT
  children,
  // SET STATE
  currentStep,
  setCurrentStep,
  initialValues,
  handlePlus,
  isDetailData,
  disableSkipToStep = false,
}) {
  const [isHoveredIcon, setIsHoveredIcon] = useState(false);

  const handleMinus = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const nextMouseEnter = () => {
    setIsHoveredIcon(true);
  };

  const nextMouseLeave = () => {
    setIsHoveredIcon(false);
  };

  const [isBackHover, setIsBackHover] = useState(false);

  const backMouseEnter = () => {
    setIsBackHover(true);
  };

  const backMouseLeave = () => {
    setIsBackHover(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmitForm}
    >
      {(props) => {
        const {
          handleSubmit,
          setSubmitting,
          setFieldTouched,
          values,
          setTouched,
          errors,
          isValid,
          validateForm,
        } = props;

        return (
          <>
            <FormErrorNotificationShared disableAlert />

            <div className="w-full  mt-4">
              <div
                className={` w-full  bg-white z-[30] sticky top-0 pb-2 
              }`}
              >
                <div className="flex items-center pt-5 px-8  ">
                  <div className={`${currentStep === 1 && "opacity-0"} `}>
                    <Button
                      onClick={handleMinus}
                      onMouseEnter={backMouseEnter}
                      onMouseLeave={backMouseLeave}
                      type={"button"}
                      className={"w-[150px] "}
                      label={
                        <div className="w-full flex items-center justify-center gap-3">
                          <ChevronLeft
                            color={`${isBackHover ? "white" : "#DD7224"}`}
                          />
                          <h1>Back</h1>
                        </div>
                      }
                    />
                  </div>
                  <div className="flex justify-center items-center w-full  ">
                    <div className="w-[800px] ">
                      <BreadcrumbSteps
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        disableSkipToStep={disableSkipToStep}
                        steps={[
                          "Personal Info",
                          "Employement Details",
                          "Identification Doc",
                          "Payment Details",
                        ]}
                      />
                    </div>
                  </div>

                  {currentStep <= 3 && (
                    <div>
                      <Button
                        onClick={() => {
                          if (validationSchema) {
                            validateForm().then((validationErrors) => {
                              if (validationErrors) {
                                setSubmitting(true);
                              }
                            });
                            handlePlus(
                              values,
                              setTouched,
                              setFieldTouched,
                              errors
                            );
                          } else {
                            setCurrentStep(currentStep + 1);
                          }
                        }}
                        onMouseEnter={nextMouseEnter}
                        onMouseLeave={nextMouseLeave}
                        className={"w-[150px]"}
                        type={"button"}
                        label={
                          <div
                            className={` w-full flex items-center justify-center gap-3`}
                          >
                            <h1>Next</h1>
                            <ChevronRight
                              color={`${isHoveredIcon ? "white" : "#DD7224"}`}
                            />
                          </div>
                        }
                      />
                    </div>
                  )}

                  {currentStep === 4 && !isDetailData && (
                    <div>
                      <Button
                        style="solid"
                        className={"w-[150px]"}
                        // IS SUBMITING
                        type={"submit"}
                        onClick={handleSubmit}
                        label={
                          <div
                            className={` w-full flex items-center justify-center gap-3`}
                          >
                            <FiSaveSvg />
                            <h1>Submit</h1>
                          </div>
                        }
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="w-full pt-6 ">
                <div className="w-full h-[calc(100vh-251px)] overflow-y-auto">
                  {children}
                </div>
              </div>
              {/* Scrollable children area */}
              {/* <div className="w-full pt-6 h-[calc(100vh-200px)] overflow-y-auto">
              <div className="w-full">{children}</div>
            </div> */}
            </div>
          </>
        );
      }}
    </Formik>
  );
}

export default FormShared;
